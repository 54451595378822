const initialState = [];

const reviewContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REVIEW_CONTENT":
      return action.payload;
    default:
      return state;
  }
};

export default reviewContentReducer;
