import axios from "axios";
import { useQuery } from "react-query";
import { config } from "../utils/config";

const fetchDetails = () =>
  axios.get(`${config.baseUrl}/userdetail`).then((res) => res.data);

export default function useGetUserDetails() {
  return useQuery("userDetails", fetchDetails, {
    retry: false,
    refetchOnWindowFocus: false,
  });
}

export function useGetUserDetailsData(userId) {
  return useQuery(
    "mentorDetails",
    () =>
      axios(`${config.baseUrl}/userdetail`, {
        params: {
          userId: userId,
        },
      }),
    {
      retry: false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
}
