import axios from "axios";
import { config } from "../utils/config";

export const getClaimStatus = async (userId) => {
  const claimStatus = await axios.get(`${config.baseUrl}/claims`, {
    params: {
      userId,
    },
  });
  return claimStatus;
};

export const postClaimStatus = async (withdrawal, userId) => {
  const claimStatus = await axios.post(`${config.baseUrl}/claims`, {
    withdrawal,
    userId,
  });
  return claimStatus;
};
