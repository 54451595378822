import { Alert } from "antd";
import React, { useContext } from "react";
import { firebaseAuth } from "../AuthProvider/Authprovider";
import "./accessDenied.css";

const AccessDenied = () => {
  const { handleSignout } = useContext(firebaseAuth);
  return (
    <div>
      <div className="alert-div">
        <Alert
          style={{
            paddingTop: "1rem",
            paddingBottom: "1rem",
            paddingLeft: "2rem",
          }}
          message="Access Denied, only admin can access."
          type="error"
          closeText="Close Now"
          onClose={async () => await handleSignout()}
        />
      </div>
    </div>
  );
};

export default AccessDenied;
