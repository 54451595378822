export const statusMap = {
  waitingForDetails: "WAITINGFORDETAILS",
  waitingForResume: "WAITINGFORRESUME",
  waitingForReview: "WAITINGFORREVIEW",
  pendingMentor: "PENDINGMENTOR",
  mentorAssigned: "MENTORASSIGNED",
  done: "DONE",
  inReview: "INREVIEW",
  sentForApproval: "SENTFORAPPROVAL",
};
