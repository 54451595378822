/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/*
 * Review Table
 *
 */

import React, { useState, useEffect } from "react";
import { Input, Select, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { statusMap } from "../../../utils/statusFlag";
import { printExperience } from "../../../utils/experience";

export default function TableFilters({
  dataSource,
  handleChangeDataSource,
  setSearch,
  setStatus,
  setReview,
  setExperience,
  setRole,
  setRound,
}) {
  //round
  const roundOptions = [1, 2, 3, 4, 5];
  const [selectedRoundItems, setSelectedRoundItems] = useState([]);
  const filteredRoundOptions = roundOptions.filter(
    (o) => !selectedRoundItems.includes(o)
  );
  //role
  const roleOptions = ["Full Time", "Part Time", "Internship"];
  const [selectedRoleItems, setSelectedRoleItems] = useState([]);
  const filteredRoleOptions = roleOptions.filter(
    (o) => !selectedRoleItems.includes(o)
  );

  //experience
  const experienceOptions = [
    ...Array.from({ length: 10 }, (_, i) => i + 1),
    15,
    20,
    25,
  ];
  const [selectedExperienceItems, setSelectedExperienceItems] = useState([]);
  const filteredExperienceOptions = experienceOptions.filter(
    (o) => !selectedExperienceItems.includes(o)
  );

  //review name
  const reviewOptions = [
    "Front End Engineer",
    "Back End Engineer",
    "Full Stack Engineer",
  ];
  const [selectedReviewItems, setSelectedReviewItems] = useState([]);
  const filteredReviewOptions = reviewOptions.filter(
    (o) => !selectedReviewItems.includes(o)
  );

  //status
  const statusOptions = [
    statusMap.waitingForDetails,
    statusMap.waitingForResume,
    statusMap.waitingForReview,
    statusMap.pendingMentor,
    statusMap.mentorAssigned,
    statusMap.inReview,
    statusMap.sentForApproval,
    statusMap.done,
  ];
  const [selectedStatusItems, setSelectedStatusItems] = useState([]);
  const filteredStatusOptions = statusOptions.filter(
    (o) => !selectedStatusItems.includes(o)
  );

  const [tagItems, setTagItems] = useState([]);
  const [tagLabels, setTagLabels] = useState([]);

  useEffect(() => {
    let index = tagItems.indexOf("Clear Filters");
    if (index !== -1 && tagItems.length === 1) {
      setTagItems([]);
    } else if (tagItems.length > 0) {
      let items = [...tagItems];
      if (index === -1) items.push("Clear Filters");
      setTagItems(items);
    }
  }, [
    selectedRoundItems,
    selectedStatusItems,
    selectedRoleItems,
    selectedExperienceItems,
    selectedReviewItems,
  ]);

  const handleRoundChange = (roundItems) => {
    setSelectedRoundItems(roundItems);
    setRound(roundItems);
    setTagItems(
      roundItems.concat(
        selectedRoleItems,
        selectedExperienceItems,
        selectedReviewItems,
        selectedStatusItems
      )
    );
  };

  const handleRoleChange = (roleItems) => {
    setSelectedRoleItems(roleItems);
    setRole(roleItems);
    setTagItems(
      selectedRoundItems.concat(
        roleItems,
        selectedExperienceItems,
        selectedReviewItems,
        selectedStatusItems
      )
    );
  };
  const handleExperienceChange = (experienceItems) => {
    setSelectedExperienceItems(experienceItems);
    setExperience(experienceItems);
    setTagItems(
      selectedRoundItems.concat(
        selectedRoleItems,
        experienceItems,
        selectedReviewItems,
        selectedStatusItems
      )
    );
  };
  const handleReviewChange = (reviewItems) => {
    setSelectedReviewItems(reviewItems);
    setReview(reviewItems);
    setTagItems(
      selectedRoundItems.concat(
        selectedRoleItems,
        selectedExperienceItems,
        reviewItems,
        selectedStatusItems
      )
    );
  };
  const handleStatusChange = (statusItems) => {
    setSelectedStatusItems(statusItems);
    setStatus(statusItems);
    setTagItems(
      selectedRoundItems.concat(
        selectedRoleItems,
        selectedExperienceItems,
        selectedReviewItems,
        statusItems
      )
    );
  };
  const handleTags = (item, index, e) => {
    e.preventDefault();
    const filterItem = item;
    if (filterItem === "Clear Filters") {
      setSelectedRoundItems([]);
      setSelectedStatusItems([]);
      setSelectedRoleItems([]);
      setSelectedExperienceItems([]);
      setSelectedReviewItems([]);
      setTagItems([]);
      setTagLabels([]);
      handleChangeDataSource(dataSource);
      setReview([]);
      setRole([]);
      setRound([]);
      setExperience([]);
      setStatus([]);
    } else {
      let itemsOfRound = selectedRoundItems.filter((a) => a !== filterItem);
      let itemsOfStatus = selectedStatusItems.filter((a) => a !== filterItem);
      let itemsOfRole = selectedRoleItems.filter((a) => a !== filterItem);
      let itemsOfExperience = selectedExperienceItems.filter(
        (a) => a !== filterItem
      );
      let itemsOfReview = selectedReviewItems.filter((a) => a !== filterItem);
      let itemsOfTags = tagItems.filter((a) => a !== filterItem);
      setSelectedRoundItems(itemsOfRound);
      setSelectedStatusItems(itemsOfStatus);
      setSelectedExperienceItems(itemsOfExperience);
      setTagItems(itemsOfTags);
      setSelectedRoleItems(itemsOfRole);
      setSelectedReviewItems(itemsOfReview);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <>
      <div className="reviewTable__header">
        <span className="reviewTable__header-title">
          <span className="reviewTable__header-left">
            <span className="reviewTable__header-text">
              <h4
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  textAlign: "left",
                }}
              >
                Filter
              </h4>
            </span>
            Round
            <span className="reviewTable__header-filter">
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedRoundItems}
                onChange={handleRoundChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredRoundOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
            Role
            <span
              className="reviewTable__header-filter"
              style={{ width: "30%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedRoleItems}
                onChange={handleRoleChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredRoleOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
            Experience
            <span
              className="reviewTable__header-filter"
              style={{ width: "27%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedExperienceItems}
                onChange={handleExperienceChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredExperienceOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {printExperience(item, true)}
                  </Select.Option>
                ))}
              </Select>
            </span>
          </span>

          <span className="reviewTable__header-right">
            Status
            <span
              className="reviewTable__header-filter"
              style={{ width: "37%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedStatusItems}
                onChange={handleStatusChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredStatusOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
            Review
            <span
              className="reviewTable__header-filter"
              style={{ width: "30%" }}
            >
              <Select
                suffixIcon={
                  <DownOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                }
                showArrow
                mode="multiple"
                label="Pro"
                value={selectedReviewItems}
                onChange={handleReviewChange}
                style={{ width: "100%" }}
                className="dont-show"
              >
                {filteredReviewOptions.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </span>
          </span>
        </span>

        <span className="reviewTable__header-search">
          <Input onChange={handleSearchChange} />
        </span>
      </div>
      {tagItems.map((item, index) => (
        <Tag id={item} closable onClose={handleTags.bind(this, item, index)}>
          {index + 1 !== tagItems.length && tagLabels[index]}
          {item}
        </Tag>
      ))}
    </>
  );
}
