import React from "react";
import MentorsDataTable from "./mentorTable/mentorTable";
import "./mentor.css";
// db is similar to firestore
export default function display2() {
  return (
    <div className="contentWrapper">
      <MentorsDataTable />
    </div>
  );
}
