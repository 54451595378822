/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import { authMethods } from "../../utils/firebase/authmethods";
import { isLoggedIn } from "../../actions/index";
import interceptor from "./interceptor";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useGetUserRole from "../../hooks/useGetUserRole";
import { config } from "../../utils/config";
import useGetUserDetails from "../../hooks/useGetUserDetails";
import { message } from "antd";
import firebase from "../../utils/firebase/index";
export const firebaseAuth = React.createContext();

const AuthProvider = (props) => {
  const initState = {
    email: "",
    password: "",
    user: {},
  };

  const [user, setUser] = useState({
    fname: "",
    lname: "",
    profilePhoto: "",
    linkedin: "",
    mentorRating: 1,
  });
  const [userCategory, setUserCategory] = useState("");
  const [initialised, setInitialised] = useState("");
  const [inputs, setInputs] = useState(initState);
  const [newUser, setNewuser] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const history = useHistory();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [showFullPageLoader, hideFullPageLoader] = useFullPageLoader();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getUserDetails = useGetUserDetails();
  const getUserRole = useGetUserRole();
  const [hasUserDetails, setHasUserDetails] = useState(false);
  const [hasUserRole, setHasUserRole] = useState(false);
  const [userId, setuserId] = useState(null);
  const [userRolePostResponses, setUserRolePostResponses] = useState(null);

  useEffect(() => {
    if (token && !initialised) {
      if (!getUserDetails.isLoading) {
        if (getUserDetails.isError) {
          console.log(getUserDetails.error.message);
        } else {
          setUser(getUserDetails.data[0]);
          setHasUserDetails(true);
        }
      }
    }
  }, [token, getUserDetails, initialised]);

  useEffect(() => {
    if (token && !initialised) {
      if (!getUserRole.isLoading) {
        if (getUserRole.isError) {
          if (getUserRole.error.response.data !== "Unauthorized")
            setUserRolePostResponses(getUserRole.error.response.data);
        } else {
          const roleUser =
            getUserRole.data.role || window.localStorage.userRole;
          window.localStorage.setItem("userRole", roleUser);
          setuserId(getUserRole.data.uuid);
          setUserCategory(roleUser);
          setHasUserRole(true);
        }
      }
    }
  }, [token, getUserRole, initialised]);

  useEffect(() => {
    if (hasUserDetails) {
      if (hasUserRole) {
        setInitialised("/");
      }
    }
  }, [hasUserDetails, hasUserRole]);

  //  tood
  // set user category as dependency
  // make 3 varibales for progress
  // 4th useeffect to monitor them

  /*
   *
   *
   */

  /*
   * Handle signin function
   *
   */

  /*
   *
   *
   */

  /*
   * Handle signout function
   *
   */
  const handleSignout = async () => {
    try {
      await authMethods.signout(setErrors, setToken);
      queryClient.clear();
      setUser(null);
      setUserCategory("");
      setUserEmail("");
      setNewuser("");
      setInitialised("");
      setUserRolePostResponses(null);
      history.push("/login");
    } catch {
      (error) => console.log(error);
    }
  };

  /*
   * Handle signin with google
   *
   */

  const handleSignInwithGoogle = async (userRole) => {
    userRole = "ADMIN";
    const userDetails = await authMethods.signingoogle(
      setErrors,
      setToken,
      setNewuser,
      setUser,
      showFullPageLoader
    );
    try {
      showFullPageLoader();
      const isnewuser = window.localStorage.newUser === "true";
      if (isnewuser) {
        await axios.post(`${config.baseUrl}/user-role`, {
          role: userRole,
        });

        // updating the token as new custom claims are added after user role creation.
        const refreshedToken = await firebase
          .auth()
          .currentUser.getIdToken(true);
        localStorage.setItem("token", refreshedToken);
        setToken(refreshedToken);
        window.localStorage.setItem("userRole", userRole);
        setUserCategory(userRole); // setuserrole

        await axios.post(`${config.baseUrl}/userdetail`, userDetails);
        const userDetailsResponse = await axios.get(
          `${config.baseUrl}/userdetail`
        );

        // set user over here
        window.localStorage.setItem("userId", userDetailsResponse.data[0].id);
        setUser(userDetailsResponse.data[0]);
        setuserId(userDetailsResponse.data[0].id);
        setInitialised("/");
        hideFullPageLoader();
      } else {
        // logging in via google
        const userRoleResponse = await axios.get(`${config.baseUrl}/user-role`);
        const roleUser = userRoleResponse.data.role;
        if (roleUser !== "ADMIN") {
          message.error({
            content: "You do not have permission to login as admin",
            style: {
              marginTop: "85vh",
            },
          });
          window.localStorage.clear();
        } else {
          dispatch(isLoggedIn());
          window.localStorage.setItem("userRole", roleUser);
          window.localStorage.setItem("userId", userRoleResponse.data.uuid);
          setUserCategory(roleUser); // setuserrole
          const userDetailsResponse = await axios.get(
            `${config.baseUrl}/userdetail`
          );
          setUser(userDetailsResponse.data[0]);
          setInitialised("/");
          hideFullPageLoader();
          setUser(userDetails);
        }
      }
    } catch (error) {
      setUserRolePostResponses(error.response.data.message);

      if (error.response.data === "User does not exist") {
        try {
          await axios.post(`${config.baseUrl}/user-role`, {
            role: userRole,
          });

          // updating the token as new custom claims are added after user role creation.
          const refreshedToken = await firebase
            .auth()
            .currentUser.getIdToken(true);
          localStorage.setItem("token", refreshedToken);
          setToken(refreshedToken);
          window.localStorage.setItem("userRole", userRole);
          setUserCategory(userRole); // setuserrole

          await axios.post(`${config.baseUrl}/userdetail`, userDetails);
          const userDetailsResponse = await axios.get(
            `${config.baseUrl}/userdetail`
          );

          // set user over here
          window.localStorage.setItem("userId", userDetailsResponse.data[0].id);
          setUser(userDetailsResponse.data[0]);
          setuserId(userDetailsResponse.data[0].id);
          setInitialised("/");
          hideFullPageLoader();
        } catch (err) {
          setUserRolePostResponses(err.response.data.message);
        }
      }
    }
  };
  /*
   *
   *
   */

  // Setup Axios interceptor for auth and invalid requests
  interceptor(handleSignout);

  return (
    <firebaseAuth.Provider
      value={{
        handleSignInwithGoogle,
        token,
        inputs,
        setInputs,
        errors,
        handleSignout,
        newUser,
        user,
        userId,
        userEmail,
        userCategory,
        initialised,
        userRolePostResponses,
      }}
    >
      {props.children}
    </firebaseAuth.Provider>
  );
};

export default AuthProvider;
