/* eslint-disable no-unused-expressions */
/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./containers/Login/Loadable";
import { firebaseAuth } from "./components/AuthProvider/Authprovider";
import Home from "./pages/Home";
import AccessDenied from "./components/accessDenied/AccessDenied";

export default function App() {
  const auth = useContext(firebaseAuth);
  const { initialised, token, userRolePostResponses } = auth;
  console.log(initialised);
  function BlockedRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          token ? (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          ) : (
            children
          )
        }
      />
    );
  }

  const responses = ["access denied", "User does not exist"];

  return (
    <Switch>
      {initialised === "/" && !responses.includes(userRolePostResponses) ? (
        <>
          <Home />
        </>
      ) : responses.includes(userRolePostResponses) ? (
        <AccessDenied />
      ) : null}
      {token ? null : <Redirect exact from="/" to="/login" />}
      <BlockedRoute path="/login">
        <Login />
      </BlockedRoute>
    </Switch>
  );
}
