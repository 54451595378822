import axios from "axios";
import { config } from "../utils/config";
import { useQuery } from "react-query";

const fetchReasons = () =>
  axios.get(`${config.baseUrl}/reassignment`).then((res) => res.data);

export default function useGetReassignmentReasons() {
  return useQuery("reasons", fetchReasons, {
    retry: false,
    refetchOnWindowFocus: false,
  });
}
