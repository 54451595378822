import { Modal } from "antd";
import axios from "axios";
import { createBrowserHistory } from "history";
import { mentorStatusAdminAPIPath } from "../../../constants/apiPaths";

const ApproveMentor = (id, name, status, e) => {
  const displayData = "Click OK to " + status + " " + name + "'s profile!";

  let statusUpdate = status + "d";
  Modal.confirm({
    content: displayData,
    async onOk() {
      const payload = {
        userId: id,
        mentorstatus: statusUpdate,
      };
      await axios
        .put(mentorStatusAdminAPIPath, payload, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then(
          (response) => {
            console.log(response);
            history.push("/mentors");
          },
          (error) => {
            console.log(error);
          }
        );
    },
    onCancel() {},
  });
};
export default ApproveMentor;

export const history = createBrowserHistory({ forceRefresh: true });
