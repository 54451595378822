/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import "./index.css";
import { Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { firestore, storage } from "../../../utils/firebase";
import { Select, Modal } from "antd";
const { Option } = Select;

export default function RoundDetails(props) {
  const [status, setStatus] = useState([]);
  const [data, setData] = useState([]);
  const [dropList, setDropList] = useState([]);
  const [currentRound, setCurrentRound] = useState(0);
  const [loading, setLoading] = useState(true);
  const [mentorData, setMentorData] = useState("");
  useEffect(async () => {
    setLoading(true);
    let currentData = {};
    let query = await firestore
      .collection("reviews")
      .doc(props.history.location.state.props.round)
      .collection("rounds")
      .get();
    for await (const documentSnapshot of query.docs) {
      setStatus(props.history.location.state.props.status);
      let round = documentSnapshot.data().round;
      let documentData = documentSnapshot.data();
      currentData[round] = documentData;
    }
    await setMentorData(props.history.location.state.props.mentorDetail);
    await setCurrentRound(props.history.location.state.props.current);
    await onChange(props.history.location.state.props.current);
    await setData(currentData);
  }, []);
  const [resume, setResume] = useState("");
  const getUrl = (path) => {
    storage
      .ref(path)
      .getDownloadURL()
      .then((url) => setResume(url));
    return resume;
  };

  useEffect(() => {
    let MenuList = [];
    let index = 0;
    for (index = 0; index < status.length; index++) {
      MenuList.push(
        <Option value={index + 1} key={index + 1}>
          Round {index + 1}
        </Option>
      );
    }
    setDropList(MenuList);
    setLoading(false);
  }, [status]);
  async function onChange(value) {
    if (status[value - 1] === "NOTSTARTED") {
      setMentorData("");
      setCurrentRound(0);
      Modal.error({
        content: "This round is yet to begin.",
      });
    } else {
      setCurrentRound(value);
      if (props.history.location.state.props.mentorDetail) {
        let query = await firestore
          .collection("user-role")
          .doc(props.history.location.state.props.mentorDetail[value])
          .collection("userdetail")
          .get();
        for (const documentSnapshot of query.docs) {
          setMentorData(documentSnapshot.data());
        }
      }
    }
  }
  let moreDetails = [];
  if (
    currentRound > 1 &&
    data[currentRound] &&
    data[currentRound].questionnaire
  ) {
    for (var key in data[currentRound].questionnaire) {
      moreDetails.push(
        <>
          <div className="orderDetail__heading">{key}</div>
          <span className="orderDetail">
            <p style={{ maxWidth: "800px" }}>
              {data[currentRound].questionnaire[key]}
            </p>
          </span>
        </>
      );
    }
  }
  return (
    <>
      {loading ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
        <div className="orderDetails__container">
          <div>
            <ArrowLeftOutlined
              onClick={() => window.history.back()}
              style={{ fontSize: 20 }}
            />
          </div>
          <Select
            showSearch
            style={{ width: 150, marginTop: "3%" }}
            placeholder="Select a round"
            optionFilterProp="children"
            defaultActiveFirstOption={true}
            defaultValue={"Round " + props.history.location.state.props.current}
            onChange={onChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dropList}
          </Select>
          <h1 style={{ marginTop: "2%" }}>Round details</h1>
          <Row
            justify="space-between"
            gutter={16}
            className="orderDetails__row"
          >
            <Col span={6}>
              <div className="orderBox">
                <span className="orderDetail__heading">Order date</span>
                {data[currentRound] ? (
                  <span className="orderDetail">
                    {new Date(data[currentRound].created).toLocaleDateString(
                      "en-US"
                    )}
                  </span>
                ) : (
                  <span className="orderDetail">-</span>
                )}
                <span className="orderDetail__heading">Due date</span>
                {data[currentRound] ? (
                  <span className="orderDetail">
                    {new Date(data[currentRound].dueDate).toLocaleDateString(
                      "en-US"
                    )}
                  </span>
                ) : (
                  <span className="orderDetail">-</span>
                )}
                <span className="orderDetail__heading">Job profile</span>
                {data[currentRound] ? (
                  <span className="orderDetail">
                    {
                      props.history.location.state.props.reviewDetails
                        .job_profile
                    }
                  </span>
                ) : (
                  <span className="orderDetail">-</span>
                )}
                <span className="orderDetail__heading">Selected plan</span>
                {data[currentRound] ? (
                  <span className="orderDetail">
                    {data[currentRound].product}
                  </span>
                ) : (
                  <span className="orderDetail">-</span>
                )}
                <span className="orderDetail__heading">Type of employment</span>
                {data[currentRound] ? (
                  <span className="orderDetail">
                    {
                      props.history.location.state.props.reviewDetails
                        .type_of_emp
                    }
                  </span>
                ) : (
                  <span className="orderDetail">-</span>
                )}
                <span className="orderDetail__heading">Experience</span>
                {data[currentRound] ? (
                  <span className="orderDetail">
                    {
                      props.history.location.state.props.reviewDetails
                        .experience
                    }
                  </span>
                ) : (
                  <span className="orderDetail">-</span>
                )}
              </div>
            </Col>
            <Col span={18}>
              <div className="orderBox">
                <div className="orderDetail__heading">Target job links</div>
                {data[currentRound] &&
                props.history.location.state.props.reviewDetails.targetJobs ? (
                  <div>
                    {props.history.location.state.props.reviewDetails.targetJobs.map(
                      (position) => (
                        <div
                          className="detail__item"
                          key={`${position.name}-${position.url}`}
                        >
                          <span>{position.name}</span>
                          <span>
                            <a
                              href={position.url}
                              target="_blank"
                              className="orderDetail__link"
                            >
                              {position.url}
                            </a>
                          </span>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <span className="orderDetail">-</span>
                )}
                <div className="orderDetail__heading">Status</div>
                {data[currentRound] ? (
                  <span className="orderDetail">
                    <p style={{ maxWidth: "800px" }}>
                      {status[currentRound - 1]}
                    </p>
                  </span>
                ) : (
                  <span className="orderDetail">-</span>
                )}
                <div className="orderDetail__heading">Resume</div>
                {data[currentRound] ? (
                  <span className="orderDetail">
                    <p style={{ maxWidth: "800px" }}>
                      <a
                        href={getUrl(data[currentRound].resume)}
                        target="_blank"
                      >
                        {data[currentRound].resume}
                      </a>
                      ;
                    </p>
                  </span>
                ) : (
                  <span className="orderDetail">-</span>
                )}
                <div className="orderDetail__heading">Details</div>
                {data[currentRound] ? (
                  <span className="orderDetail">
                    <p style={{ maxWidth: "800px" }}>
                      {
                        props.history.location.state.props.reviewDetails
                          .review_extra_details
                      }
                    </p>
                  </span>
                ) : (
                  <span className="orderDetail">-</span>
                )}

                {currentRound > 1 &&
                data[currentRound] &&
                data[currentRound].questionnaire
                  ? moreDetails
                  : ""}
                {mentorData ? (
                  <span className="orderDetail__heading">Mentor FNAME</span>
                ) : (
                  ""
                )}
                {mentorData ? (
                  <span className="orderDetail">{mentorData.fname}</span>
                ) : (
                  ""
                )}
                {mentorData ? (
                  <span className="orderDetail__heading">Mentor LNAME</span>
                ) : (
                  ""
                )}
                {mentorData ? (
                  <span className="orderDetail">{mentorData.lname}</span>
                ) : (
                  ""
                )}
                {mentorData ? (
                  <span className="orderDetail__heading">LinkedIn</span>
                ) : (
                  ""
                )}
                {mentorData ? (
                  <span className="orderDetail">{mentorData.linkedin}</span>
                ) : (
                  ""
                )}
                {mentorData ? (
                  <span className="orderDetail__heading">Mentor Rating</span>
                ) : (
                  ""
                )}
                {mentorData ? (
                  <span className="orderDetail">{mentorData.mentorRating}</span>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
