/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */

import { Button, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { config } from "../../../utils/config";
import ReviewDetails from "../reviewDetails";
import AssignMentorTable from "./assignMentorTable";
import "./assignMentorTable.css";

export default function ReviewTable() {
  const [datas, setdatas] = useState([]);
  const [page, setPage] = useState(1);
  const [mentorData, setMentorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState("allReviews");
  const [allData, setAllData] = useState([]);

  const { orderId } = useParams();

  const oneDay = 24 * 60 * 60 * 1000;

  const onchangeAll = () => {
    setToggle("allReviews");
  };

  const onchangeActionReq = () => {
    setToggle("actionRequired");
  };

  const onchangeOverDue = () => {
    setToggle("overdue");
  };

  useEffect(async () => {
    setLoading(true);

    let mentorDataList = [];
    let mdata = [];
    const payload = {
      timestamp: 0,
      useCase: "USERROLE_MENTORS",
      pageType: "All",
    };
    await axios
      .post(`${config.baseUrl}/admin`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        mdata = res.data;
        // SK "2+ limit"  is any value > limit to display > ( next page sign in UI which is controlled by setTotalData)
        // if (res.data.length === limit) setTotalData(2 + limit);
        // else setTotalData(limit);
      });

    if (mdata.length > 0) {
      for (var i in mdata) {
        var index = mdata.findIndex((val) => val.id === mdata[i]["id"]);

        var mId = mdata[index]["id"];
        var name = mdata[index]["fname"] + " " + mdata[index]["lname"];

        var obj = {
          name: name,
          mentorID: mId,
          mentorStatus: mdata[index]["mentorStatus"],
        };

        mentorDataList.push(obj);
      }
    }

    setMentorData(mentorDataList);

    const payloadReview = {
      timestamp: 0,
      useCase: "REVIEW_DETAILS",
      pageType: "NEXT",
    };
    await axios
      .post(`${config.baseUrl}/admin`, payloadReview, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setAllData(res.data.reviewData);
        if (toggle === "allReviews") setdatas(res.data);
        if (toggle === "actionRequired")
          setdatas({
            reviewData:
              res.data &&
              res.data.reviewData &&
              res.data.reviewData.filter(
                (dta) =>
                  dta.status[dta.currentRound - 1] === "PENDINGMENTOR" ||
                  dta.status[dta.currentRound - 1] === "SENTFORAPPROVAL"
              ),
            userIdMap: res.data.userIdMap,
          });
        if (toggle === "overdue") {
          setdatas({
            reviewData:
              res.data &&
              res.data.reviewData &&
              res.data.reviewData.filter(
                (dta) =>
                  (dta.status[dta.currentRound - 1] === "WAITINGFORDETAILS" &&
                    Math.round(
                      ((new Date().getTime() -
                        new Date(dta.menteeDueDate).getTime()) /
                        oneDay) *
                        -1
                    ) <= 0) ||
                  (dta.status[dta.currentRound - 1] === "MENTORASSIGNED" &&
                    Math.round(
                      ((new Date().getTime() -
                        new Date(dta.dueDate).getTime()) /
                        oneDay) *
                        -1
                    ) <= 0)
              ),
            userIdMap: res.data.userIdMap,
          });
        }
      });

    setLoading(false);
  }, [orderId, toggle]);

  const handlePaginatedData = (paginatedData) => {
    setdatas(paginatedData);
  };

  const handleCurrentPage = (currentPage) => {
    setPage(currentPage);
  };

  return (
    <>
      {loading ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
        <Switch>
          <Redirect exact from="/reviews" to="/reviews/home" />
          <Route
            path={"/reviews/home"}
            render={() => {
              return (
                <>
                  <Button onClick={onchangeAll}>
                    All Reviews ({allData.length})
                  </Button>
                  <Button onClick={onchangeActionReq}>
                    Action Required (
                    {allData &&
                      allData.filter(
                        (dta) =>
                          dta.status[dta.currentRound - 1] ===
                            "PENDINGMENTOR" ||
                          dta.status[dta.currentRound - 1] === "SENTFORAPPROVAL"
                      ).length}
                    )
                  </Button>
                  <Button onClick={onchangeOverDue}>
                    Over Due (
                    {allData &&
                      allData.filter(
                        (dta) =>
                          (dta.status[dta.currentRound - 1] ===
                            "WAITINGFORDETAILS" &&
                            Math.round(
                              ((new Date().getTime() -
                                new Date(dta.menteeDueDate).getTime()) /
                                oneDay) *
                                -1
                            ) <= 0) ||
                          (dta.status[dta.currentRound - 1] ===
                            "MENTORASSIGNED" &&
                            Math.round(
                              ((new Date().getTime() -
                                new Date(dta.dueDate).getTime()) /
                                oneDay) *
                                -1
                            ) <= 0)
                      ).length}
                    )
                  </Button>
                  <AssignMentorTable
                    data={datas}
                    orderId={orderId}
                    currentPage={page}
                    handlePaginatedData={handlePaginatedData}
                    mentorData={mentorData}
                    handleCurrentPage={handleCurrentPage}
                    setdatas={setdatas}
                  />
                </>
              );
            }}
          />
          <Route
            path="/reviews/details/:userid"
            render={(props) => <ReviewDetails {...props} />}
          />
          <Route
            path={"/reviews/filters/:orderid"}
            render={() => {
              return (
                <AssignMentorTable
                  data={datas}
                  orderId={orderId}
                  currentPage={page}
                  handlePaginatedData={handlePaginatedData}
                  mentorData={mentorData}
                  handleCurrentPage={handleCurrentPage}
                  setdatas={setdatas}
                />
              );
            }}
          />
        </Switch>
      )}
    </>
  );
}
