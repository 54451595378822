/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
// import { Table } from 'ant-table-extensions';
import { config } from '../../../utils/config';
import axios from 'axios';
import 'antd/dist/antd.css';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import MentorProfile from '../mentorProfile';
import './mentorTable.css';
// import { firestore } from '../../../utils/firebase';

import ApproveMentor from './approveMentor';

export default function MentorTable() {
    const limit = 25;
    const [mentorData, setMentorData] = useState([]);
    const [datas, setDatas] = useState([]);

    const [loading, setLoading] = useState(false);
    const [totalData, setTotalData] = useState(0);

    // SK Comment - We can query user-role only once
    // By SK  -- Fetch user-role and mentor Status data in approve mentor when admin clicks on it
    useEffect(async () => {
        setLoading(true);
        let mData = [];
        const payload = {
            timestamp: 0,
            useCase: 'USERROLE_MENTORS',
            pageType: 'NEXT',
        };
        await axios
            .post(`${config.baseUrl}/admin`, payload, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            })
            .then((res) => {
                mData = res.data;
                // SK "2+ limit"  is any value > limit to display > ( next page sign in UI which is controlled by setTotalData)
                if (res.data.length === limit) setTotalData(2 + limit);
                else setTotalData(limit);
            });

        setLoading(false);
        // await setdataStatus(mentorStatusData);
        setMentorData(mData);
    }, []);

    // Prepare data for sending to ApproveMentor component
    useEffect(() => {
        let tempData = [];

        if (mentorData.length > 0) {
            for (var i in mentorData) {
                var index = mentorData.findIndex(
                    (val) => val.id === mentorData[i]['id']
                );

                var mId = mentorData[index]['id'];
                var mentorName =
                    mentorData[index]['fname'] +
                    ' ' +
                    mentorData[index]['lname'];

                // SK prepare all available data from user-role to be passed as prop
                var obj = {
                    mentorId: mId,
                    mentorName: mentorName,
                    status: mentorData[index]['mentorstatus'],
                    fname: mentorData[index]['fname'],
                    lname: mentorData[index]['lname'],
                    mentorEmail: mentorData[index]['email'],
                    linkedin: mentorData[index]['linkedin'],
                    role: mentorData[index]['role'],
                    created: mentorData[index]['created'],
                };
                if (tempData.indexOf(obj) === -1) {
                    tempData.push(obj);
                    console.log(tempData);
                }
                //tempData.push(obj);
            }
        }

        setLoading(false);
        setDatas(tempData);
    }, [mentorData]);

    console.log('datas :>> ', datas);
    return (
        <>
            {loading ? (
                <div className='spinner'>
                    <Spin />
                </div>
            ) : (
                <Switch>
                    <Redirect exact from='/mentors' to='/mentors/home' />
                    {/* <Route path="/mentors/home" component={ApproveMentor} /> */}
                    <Route
                        path={'/mentors/home'}
                        render={() => {
                            return (
                                <ApproveMentor
                                    data={datas}
                                    totalData={totalData}
                                    setDatas={setDatas}
                                    setTotalData={setTotalData}
                                    setMentorData={setMentorData}
                                />
                            );
                        }}
                    />
                    <Route
                        path='/mentors/:userid'
                        render={(props) => <MentorProfile {...props} />}
                    />
                </Switch>
            )}
        </>
    );
}
