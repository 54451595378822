import axios from "axios";
import { message } from "antd";
import { useHistory } from "react-router-dom";

export default function interceptor(handleSignout) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();
  if (window.localStorage.token) {
    /*
     * Interceptors for all requests
     *
     */
    axios.interceptors.request.use(
      (request) => {
        request.headers.Authorization = `Bearer ${window.localStorage.token}`;
        return request;
      },
      (error) => Promise.reject(error)
    );
    /*
     *
     *
     */

    /*
     * Interceptors for all responses
     *
     */
    axios.interceptors.response.use(
      undefined,
      function axiosRetryInterceptor(err) {
        let errorMessage = err;
        if (
          err.response.status === 401 ||
          err.response.status === 403 ||
          err.response.data.message === "Unauthorized"
        ) {
          if (history.location.pathname !== "/login") {
            errorMessage = "Session expired. Please login again.";
            message.error({
              content: `${errorMessage}`,
              style: {
                marginTop: "80vh",
              },
            });
            setTimeout(() => {
              handleSignout();
            }, 3000);
          }
        }
        return Promise.reject(err);
      }
    );
    /*
     *
     *
     */
  }
}
