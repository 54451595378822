/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import { Avatar, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { expandLeftbar } from "../../../actions/index";
import { useGetUserRoleData } from "../../../hooks/useGetUserRole";
import "./index.css";

export default function Profile(props) {
  const { userid } = useParams();

  const firstName = props.history.location.state.props[0];
  const lastName = props.history.location.state.props[1];

  let menteeEmail;
  let linkedIn;

  const [mentorStatusForm] = Form.useForm();

  const [profilePhoto] = useState("");

  const collapsed = useSelector((state) => state.collapseLeftbar);
  const dispatch = useDispatch();
  if (collapsed) {
    dispatch(expandLeftbar());
  }

  // SK: For userQuery hook, We need to fetch data in data only and then we can rename it to another variable like  userRoleData
  const { data: userRoleData } = useGetUserRoleData(userid);

  if (userRoleData && userRoleData.data) {
    menteeEmail = userRoleData.data.email;
    linkedIn = userRoleData.data.linkedin;
  }

  return (
    <div className="profile__container">
      <Row>
        <h2
          style={{
            fontWeight: 700,
            paddingTop: 10,
            paddingBottom: 10,
            textAlign: "left",
          }}
        >
          Profile
        </h2>
      </Row>
      <Row gutter={16}>
        <Col
          xs={24}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className="profile__avatar-box"
        >
          <span className="profile__avatar">
            <Avatar
              src={profilePhoto}
              size={100}
              style={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
            />
          </span>
        </Col>
        <Col xs={24} sm={18} md={18} lg={18} xl={18}>
          <Form
            form={mentorStatusForm}
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item label="First Name" name="fname">
              <Row>
                <Col span={22}>
                  <Input
                    defaultValue={firstName}
                    value={firstName}
                    size="medium"
                    readonly="readonly"
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="Last Name" name="lname">
              <Row>
                <Col span={22}>
                  <Input
                    defaultValue={lastName}
                    value={lastName}
                    size="medium"
                    readonly="readonly"
                  />
                </Col>
              </Row>
            </Form.Item>

            <Form.Item name="linkedin" label="LinkedIn profile">
              <Row>
                <Col span={22}>
                  <Input
                    type="url"
                    placeholder="-"
                    size="medium"
                    defaultValue={linkedIn}
                    value={linkedIn}
                    readonly="readonly"
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Row>
                <Col span={22}>
                  <Input
                    size="medium"
                    defaultValue={menteeEmail}
                    value={menteeEmail}
                    readonly="readonly"
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
