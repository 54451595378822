/* eslint-disable react/no-array-index-key */
import React from "react";
import { Link } from "react-router-dom";
// Get the spinner

const columns = [
  {
    title: "Mentee Name",
    dataIndex: "fname",
    key: "fname",
    width: 200,
    fixed: "left",
    sorter: {
      compare: (a, b) =>
        a.fname[2].toLowerCase().localeCompare(b.fname[2].toLowerCase()),
    },
    render: (fname) => (
      <>
        {fname[0] !== "-" ? (
          <>
            <Link
              to={{
                pathname: `/mentee/${fname[3]}`,
                state: { props: fname },
              }}
            >
              {fname[2]}
            </Link>
          </>
        ) : (
          <div>-</div>
        )}
      </>
    ),
  },
  {
    title: "Order Key",
    dataIndex: "key",
    key: "key",
    width: 150,
    render: (key) => (
      <Link to={{ pathname: `/reviews/filters/${key}` }}>{key}</Link>
    ),
  },
  {
    title: "Created",
    dataIndex: "created",
    key: "created",
    width: 100,
    sorter: {
      compare: (a, b) => Date.parse(a.created) - Date.parse(b.created),
    },
  },
  {
    title: "Updated",
    dataIndex: "updated",
    key: "updated",
    width: 100,
    sorter: {
      compare: (a, b) => Date.parse(a.updated) - Date.parse(b.updated),
    },
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    width: 150,
    sorter: {
      compare: (a, b) => a.product.localeCompare(b.product),
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 150,
    sorter: {
      compare: (a, b) => a.status.localeCompare(b.status),
    },
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: 100,
    render: (price) => <div>${price}.0</div>,
    sorter: {
      compare: (a, b) => a.price - b.price,
    },
  },
];

export default columns;
