import axios from "axios";
import { useQuery } from "react-query";
import { config } from "../utils/config";

const fetchRole = () =>
  axios.get(`${config.baseUrl}/user-role`).then((res) => res.data);

export default function useGetUserRole() {
  return useQuery("userRole", fetchRole, {
    retry: false,
    refetchOnWindowFocus: false,
  });
}

export function useGetUserRoleData(userId) {
  return useQuery(
    "userRoleData1",
    async () =>
      await axios(`${config.baseUrl}/user-role`, {
        params: { userId: userId },
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
}
