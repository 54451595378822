import { Button, Form, Input, notification, Select, Upload } from "antd";
import { Option } from "antd/lib/mentions";
import axios from "axios";
import React from "react";
import { postResources } from "../../hooks/useResources";
import "./index.css";

function Resource() {
  const uploadFile = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const config = {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    try {
      const res = await axios.post(
        "http://localhost:5001/faang-path-test/us-central1/faangpath/resource/file",
        file,
        config
      );

      onSuccess("Ok");
      console.log("server res: ", res);
    } catch (err) {
      console.log("Error: ", err);
      onError({ err });
    }
  };

  const submitResource = (formValues) => {
    postResources([formValues]).then((res) => notification.open(res));
  };

  return (
    <>
      <div className="heading">Add Resources</div>
      <div className="add-resources">
        <div className="resources-form-div">
          <Form
            layout="vertical"
            className="resources-form"
            onFinish={submitResource}
          >
            <Form.Item label="Title" name="title">
              <Input />
            </Form.Item>
            <Form.Item label="Subtitle" name="subtitle">
              <Input />
            </Form.Item>
            <Form.Item name="image" label="Image URL">
              <Input type="url" placeholder="Enter Resource Image URL" />
            </Form.Item>
            <Form.Item name="url" label="Url">
              <Input type="url" placeholder="Enter Resource URL" />
            </Form.Item>
            <Form.Item name="role" label="Role">
              <Select>
                <Option value="mentor">Mentor</Option>
                <Option value="mentee">Mentee</Option>
              </Select>
            </Form.Item>
            <Form.Item name="link" label="Link Type">
              <Select>
                <Option value="url">Url</Option>
                <Option value="bucket">Bucket</Option>
              </Select>
            </Form.Item>
            <Form.Item name="category" label="Category">
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="add-resources-or">OR</div>
        <div className="upload-json">
          <Upload customRequest={uploadFile}>
            <button type="submit">Upload</button>
          </Upload>
        </div>
      </div>
    </>
  );
}

export default Resource;
