import OrderTable from "./orderTable/orderTable";
import "./orders.css";

export default function display1() {
  //    const orders = mentorReviewData.length;
  return (
    <div className="contentWrapper">
      <OrderTable />
    </div>
  );
}
