import React from "react";
import Navbar from "../../containers/Navbar/Loadable";
import Page from "../Page";
import "./home.css";

export default class Home extends React.Component {
  render() {
    return (
      <>
        <Navbar />
        <Page />
      </>
    );
  }
}
