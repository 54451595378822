export const initialPayment = () => ({
  type: "INITIAL_PAYMENT_DONE",
});
export const isLoggedIn = () => ({
  type: "LOGIN",
});
export const collapseLeftbar = () => ({
  type: "COLLAPSE_LEFTBAR",
});
export const expandLeftbar = () => ({
  type: "EXPAND_LEFTBAR",
});
export const reviewContent = (content) => ({
  type: "SET_REVIEW_CONTENT",
  payload: content,
});
export const showLoader = () => ({
  type: "SHOW_LOADER",
});
export const hideLoader = () => ({
  type: "HIDE_LOADER",
});
