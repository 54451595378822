import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { config } from "../../utils/config";
import "./index.css";

const PushNotification = () => {
  const [notificationTitle, setNotificationTitle] = useState(null);
  const [notificationDescription, setNotificationDescription] = useState(null);
  const [notificationUrl, setNotificationUrl] = useState(null);
  const [user, setUser] = useState(null);
  const [mentee, setMentee] = useState([]);
  const [allMentee, setAllMentee] = useState([]);
  const [mentor, setMentor] = useState([]);
  const [allMentor, setAllMentor] = useState([]);
  const { Option } = Select;

  const formRef = useRef();

  const onTitleChange = (e) => {
    setNotificationTitle(e.target.value);
  };
  const onDescChange = (e) => {
    setNotificationDescription(e.target.value);
  };
  const onUrlChange = (e) => {
    setNotificationUrl(e.target.value);
  };
  const onUserChange = (e) => {
    setUser(e);
  };
  const onMenteeChange = (e) => {
    setMentee(e);
  };
  const onMentorChange = (e) => {
    setMentor(e);
  };

  useEffect(() => {
    getMentors();
    getMentees();
  }, [user]);

  const getMentors = async () => {
    const payload = {
      timestamp: 0,
      useCase: "USERROLE_MENTORS",
      pageType: "All",
    };
    await axios
      .post(`${config.baseUrl}/admin`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setAllMentor(res.data);
      });
  };

  const getMentees = async () => {
    const payload = {
      timestamp: 0,
      useCase: "USERROLE_MENTEES",
      pageType: "All",
    };
    await axios
      .post(`${config.baseUrl}/admin`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setAllMentee(res.data);
      });
  };

  const submitForm = async (e) => {
    formRef.current.resetFields();
    const payload = {
      title: notificationTitle,
      description: notificationDescription,
      url: notificationUrl,
    };
    if (user !== "others") {
      Object.assign(payload, { USECASE: user });
    } else {
      Object.assign(payload, { users_to_notify: mentee.concat(mentor) });
    }
    await axios
      .post(`${config.baseUrl}/pushNotification`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setMentee([]);
        setMentor([]);
        setAllMentee([]);
        setAllMentor([]);
        setNotificationDescription(null);
        setNotificationUrl(null);
        setNotificationTitle(null);
        setUser(null);
      });
  };

  return (
    <>
      <div className="heading">Push Notification</div>
      <div className="pushNotification">
        <div className="pushNotification-div">
          <Form
            layout="vertical"
            className="pushNotification-form"
            onFinish={submitForm}
            ref={formRef}
          >
            <Form.Item
              label="Notification Title"
              name="title"
              placeholder="Notification Title"
              required
            >
              <Input required onChange={onTitleChange} maxLength="100" />
            </Form.Item>
            <Form.Item
              required
              label="Notification description"
              name="description"
              placeholder="Notification description"
            >
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 50 }}
                required
                onChange={onDescChange}
                maxLength="250"
              />
            </Form.Item>
            <Form.Item name="url" label="Page URL" required>
              <Input
                placeholder="Enter URL where user will land."
                required
                onChange={onUrlChange}
              />
            </Form.Item>
            <Form.Item required label="Select Recievers">
              <Select
                placeholder="Select a Option"
                onChange={onUserChange}
                required
              >
                <Option value="MENTOR">To All Mentors</Option>
                <Option value="MENTEE">To All Mentees</Option>
                <Option value="others">others</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Mentor" required>
              <Select
                mode="multiple"
                disabled={user !== "others"}
                placeholder="Select a Option"
                onChange={onMentorChange}
                showSearch
                required
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input)
                }
              >
                {allMentor.map((data) => (
                  <Option value={data.id}>
                    {`${data.fname} ${data.lname}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Mentee" required>
              <Select
                mode="multiple"
                disabled={user !== "others"}
                placeholder="Select a Option"
                onChange={onMenteeChange}
                required
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input)
                }
              >
                {allMentee.map((data) => (
                  <Option value={data.id}>
                    {`${data.fname} ${data.lname}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default PushNotification;
