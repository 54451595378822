/* eslint-disable react/jsx-no-duplicate-props */
import { Tag } from "antd";
import { Link } from "react-router-dom";

// record.menteeData is menteeData from assignMentorTable 0 -> userid, 1 --> [ fname, id, linkedin, lname, mentorRating, profilePhoto]
const columns = [
  {
    title: "Mentee",
    dataIndex: "mentee",
    key: "mentee",
    width: 200,
    render: (mentee, record) => (
      <>
        <Link
          to={{
            pathname: `/mentee3/${record.menteeData[0]}`,
            state: { props: record.menteeData[1] },
          }}
        >
          {mentee}
        </Link>
      </>
    ),
    sorter: {
      compare: (a, b) =>
        a.mentee.toLowerCase().localeCompare(b.mentee.toLowerCase()),
    },
  },
  {
    title: "Mentor",
    dataIndex: "mentorName",
    key: "mentorName",
    width: 280,
    sorter: {
      compare: (a, b) => a.mentorName.localeCompare(b.mentorName),
    },
  },
  {
    title: "Assign Mentor",
    dataIndex: "mentor",
    key: "mentor",
    width: 280,
    render: (mentor) => <>{mentor[0]}</>,
  },
  {
    title: "Product Name",
    dataIndex: "reviewName",
    key: "reviewName",
    width: 150,
    sorter: {
      compare: (a, b) => a.reviewName.localeCompare(b.reviewName),
    },
  },
  {
    title: "Total Rounds",
    dataIndex: "rounds",
    key: "rounds",
    width: 100,
    sorter: {
      compare: (a, b) => a.rounds - b.rounds,
    },
  },
  {
    title: "Current Round Status",
    dataIndex: "status",
    key: "status",
    width: 200,
    render: (status) => (
      <>
        {status[0] === "SENTFORAPPROVAL" ? (
          <Link
            to
            to={{
              pathname: `/feedback/${status[1]}`,
              state: {
                props: status[1],
                currentRoundId: status[2],
              },
            }}
          >
            {status[0]}
          </Link>
        ) : (
          <div>{status[0]}</div>
        )}
      </>
    ),
    sorter: {
      compare: (a, b) => a.status[0].localeCompare(b.status[0]),
    },
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    width: 150,
    sorter: {
      compare: (a, b) => a.role.localeCompare(b.role),
    },
  },

  {
    title: "Experience",
    dataIndex: "experience",
    key: "experience",
    width: 100,
    render: (experience) => <>{experience === -1 ? "-" : experience}</>,
    sorter: {
      compare: (a, b) => a.experience - b.experience,
    },
  },
  {
    title: "Current Round No",
    dataIndex: "roundNo",
    key: "roundNo",
    width: 100,
    sorter: {
      compare: (a, b) => a.roundNo - b.roundNo,
    },
  },
  {
    title: "Incentive",
    dataIndex: "mentorIncentive",
    key: "mentorIncentive",
    width: 120,
    sorter: {
      compare: (a, b) => a.mentorIncentive - b.mentorIncentive,
    },
  },
  {
    title: "Title",
    dataIndex: "productTitle",
    key: "productTitle",
    width: 150,
    sorter: {
      compare: (a, b) => a.productTitle.localeCompare(b.productTitle),
    },
  },
  {
    title: "Due Date",
    dataIndex: "dueDate",
    key: "dueDate",
    width: 150,
    sorter: {
      compare: (a, b) => Date.parse(a.dueDate) - Date.parse(b.dueDate),
    },
  },
  {
    title: "Created",
    dataIndex: "created",
    key: "created",
    width: 150,
    sorter: {
      compare: (a, b) => Date.parse(a.created) - Date.parse(b.created),
    },
  },
  {
    title: "Updated",
    dataIndex: "updated",
    key: "updated",
    width: 150,
    sorter: {
      compare: (a, b) => Date.parse(a.updated) - Date.parse(b.updated),
    },
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    width: 200,
    render: (tags) => (
      <>
        {tags.map((tag) => (
          <Tag key={`Tags-${tag}`}>{tag.toUpperCase()}</Tag>
        ))}
      </>
    ),
  },
  {
    title: "Round Details",
    dataIndex: "roundDetails",
    key: "roundDetails",
    width: 280,
    render: (roundDetails) => (
      <Link
        to
        to={{
          pathname: `/reviews/details/${roundDetails[0]}`,
          state: {
            props: {
              round: roundDetails[0],
              mentorDetail: roundDetails[1],
              status: roundDetails[2],
              reviewDetails: roundDetails[4],
              current: roundDetails[3],
            },
          },
        }}
      >
        Open
      </Link>
    ),
  },
];

export default columns;
