/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApproveMentor from "./approveMentor";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Modal,
  message,
  Spin,
  Select,
  Checkbox,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { config } from "../../../utils/config";
import axios from "axios";
import {
  getClaimStatus,
  postClaimStatus,
} from "../../../hooks/useGetClaimStatus";
import { storage } from "../../../utils/firebase";
import { expandLeftbar } from "../../../actions/index";
import "./index.css";

import { useGetUserDetailsData } from "../../../hooks/useGetUserDetails";
import { useGetMentorStatusData } from "../../../hooks/useGetMentorStatus";
import { printExperience } from "../../../utils/experience";

const { Option } = Select;

export default function Profile(props) {
  const userId = props.history.location.state.props[0];
  const mentorFullName = props.history.location.state.props[1];
  const mentorStatus = props.history.location.state.props[2];
  const mentorEmail = props.history.location.state.props[3];
  const mentorLinkedIn = props.history.location.state.props[4];
  // const mentorRole = props.history.location.state.props[5];
  const mentorFirstName = props.history.location.state.props[6];
  const mentorLastName = props.history.location.state.props[7];

  const { userid } = useParams();
  const [mentorStatusForm] = Form.useForm();
  const [isModalVisible, setModalVisible] = useState(false);
  const [isClaimModalVisible, setClaimModalVisible] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [loading, setLoading] = useState(true);

  const [userDetailsData, setUserDetailsData] = useState([]);
  const [mentorStatusData, setMentorStatusData] = useState([]);

  const contributionOptions = [
    { label: "Resume Review*", value: "Resume Review" },
    { label: "Mock Interviews*", value: "Mock Interviews" },
    { label: "1-1 Mentorship*", value: "1-1 Mentorship" },
    { label: "Resume Writing*", value: "Resume Writing" },
    { label: "LinkedIn Reviews*", value: "LinkedIn Reviews" },
    {
      label: "LinkedIn Optimizations (Writing)*",
      value: "LinkedIn Optimizations (Writing)",
    },
    {
      label: "Attending Clubhouse sessions",
      value: "Attending Clubhouse sessions",
    },
    {
      label: "Webinars / Instagram / Youtube / LinkedIn Live sessions",
      value: "Webinars / Instagram / Youtube / LinkedIn Live sessions",
    },
    {
      label: "Helping refer candidates (via Discord community)",
      value: "Helping refer candidates (via Discord community)",
    },
  ];

  const rolesOptions = [
    { label: "Software Engineer", value: "Software Engineer" },
    {
      label: "Machine Learning Engineer",
      value: "Machine Learning Engineer",
    },
    { label: "Data Analyst", value: "Data Analyst" },
    {
      label: "Program Manager",
      value: "Product Manager",
    },
    {
      label: "Information Security Analyst",
      value: "Information Security Analyst",
    },
    {
      label: "IT Manager",
      value: "IT Manager",
    },
    {
      label: "Web Developer",
      value: "Web Developer",
    },
    {
      label: "Mobile Application Developer",
      value: "Mobile Application Developer",
    },
    { label: "UI/UX Designer", value: "UI/UX Designers" },
    { label: "Business analyst", value: "Mock InterviewsBusiness analyst" },
  ];
  const {
    data: getUserDetails,
    error: errorUD,
    isError: isErrorUD,
    loading: loadingUD,
  } = useGetUserDetailsData(userid);
  const {
    data: getMentorStatus,
    error: errorMS,
    isError: isErrorMS,
    loading: loadingMS,
  } = useGetMentorStatusData(userid);

  const [incentive, setIncentive] = useState();

  const [claim, setClaim] = useState();
  const collapsed = useSelector((state) => state.collapseLeftbar);
  const dispatch = useDispatch();

  if (collapsed) {
    dispatch(expandLeftbar());
  }

  // props --> mentorDetail from approveMentor
  // mentorDetail [0,1,2] = [mentorId, mentorName, status] // refer line 228 and 248 in approveMentor

  useEffect(() => {
    if (!loadingUD) {
      if (!isErrorUD) {
        if (getUserDetails && getUserDetails.data && getUserDetails.data[0]) {
          setUserDetailsData(getUserDetails ? getUserDetails.data[0] : []);
          storage
            .ref(getUserDetails.data[0].profilePhoto)
            .getDownloadURL()
            .then((url) => {
              setProfilePhoto(url);
            });
        }
      } else {
        console.log(errorUD.message);
      }
    }
  }, [getUserDetails]);

  useEffect(() => {
    if (!loadingMS) {
      if (!isErrorMS) {
        if (getMentorStatus && getMentorStatus.data) {
          setMentorStatusData(getMentorStatus.data);
        }
      } else {
        console.log(errorMS.message);
      }
    }
  }, [getMentorStatus]);

  useEffect(async () => {
    if (
      userDetailsData &&
      userDetailsData.mentorIncentive >= 0 &&
      userDetailsData.mentorIncentive <= 100
    ) {
      setIncentive(userDetailsData.mentorIncentive);
    }
  }, [userDetailsData]);

  console.log(
    mentorStatusData.interestedInContributing
      ? mentorStatusData.interestedInContributing[
          mentorStatusData.interestedInContributing.length - 1
        ]
      : null
  );

  useEffect(async () => {
    setLoading(true);
    const resp = await getClaimStatus(userId);
    if (resp) {
      setClaim(resp.data.withdrawal);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!isModalVisible) setLoading(false);
  }, [isModalVisible]);
  const handleOk = async () => {
    if (incentive <= 100 && incentive >= 0) {
      try {
        setLoading(true);

        const payload = {
          id: userId,
          mentorIncentive: incentive,
        };
        await axios
          .put(`${config.baseUrl}/userdetail`, payload, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            setIncentive(incentive);
            message.success({
              content: `Mentor incentive update is successful`,
              style: { marginTop: "85vh" },
            });
          });
        setModalVisible(false);
        setLoading(false);
      } catch (error) {
        console.log(error);
        return;
      }
    } else {
      // setIncentive to old value from history and throw error
      setIncentive(props.history.location.state.props.mentorIncentive);
      message.error({
        content: `Mentor Incentive is not valid`,
        style: {
          marginTop: "85vh",
        },
      });
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleClaimCancel = () => {
    setClaimModalVisible(false);
  };

  const handleClaimOk = async () => {
    try {
      await postClaimStatus(claim, userid);
      message.success({
        content: `Claim updated successfully`,
        style: {
          marginTop: "85vh",
        },
      });
      setClaimModalVisible(false);
    } catch (e) {
      message.error({
        content: e.response.data.message || "Some internal server occurred",
        style: {
          marginTop: "85vh",
        },
      });
      setClaimModalVisible(false);
    }
  };
  function onChange(value) {
    setClaim(value);
  }
  return (
    <>
      {loading ? (
        <div className="spinner" style={{ height: "100vh" }}>
          <Spin />
        </div>
      ) : (
        <div className="profile__container">
          <Modal
            title="Update claims"
            visible={isClaimModalVisible}
            onOk={handleClaimOk}
            onCancel={handleClaimCancel}
          >
            <Col>
              <Row>
                <Col span={12}> Withdrawal </Col>
                <Col span={12}>
                  <Select
                    defaultValue={claim}
                    style={{ width: "100%" }}
                    onChange={onChange}
                  >
                    <Option value={false}>False</Option>
                    <Option value={true}>True</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
          </Modal>
          <Modal
            title="Change mentor incentive"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Col>
              <Row>
                <Col span={12}>Currency</Col>
                <Col span={12}>
                  <Select
                    disabled={true}
                    defaultValue={userDetailsData.currency}
                    style={{ width: "100%" }}
                  >
                    <Option value="INR">INR</Option>
                    <Option value="USD">USD</Option>
                    <Option value="NONE">NONE</Option>
                  </Select>
                  {/* <Input value={currency} onChange={(e)=>{setCurrency(e.target.value)}}/> */}
                </Col>
              </Row>
              <Row style={{ marginTop: "3%" }}>
                <Col span={12}>Mentor Incentive (0-100)</Col>
                <Col span={12}>
                  <Input
                    // type="number"
                    defaultValue={incentive}
                    value={incentive}
                    onChange={(e) => {
                      setIncentive(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Modal>
          <ArrowLeftOutlined
            onClick={() => window.history.back()}
            style={{ fontSize: 20 }}
          />
          <Row>
            {/* <br/> */}
            <h2
              style={{
                fontWeight: 700,
                paddingTop: 10,
                paddingBottom: 10,
                textAlign: "left",
              }}
            >
              Profile
            </h2>
          </Row>
          <Row gutter={16}>
            <Col
              xs={24}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="profile__avatar-box"
            >
              <span className="profile__avatar">
                <Avatar
                  src={profilePhoto}
                  size={100}
                  style={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                />
              </span>
              <Button onClick={() => setModalVisible(true)}>
                Change Incentive
              </Button>
              <br />
              <Button onClick={() => setClaimModalVisible(true)}>
                Update Claims
              </Button>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18} xl={18}>
              <Form
                form={mentorStatusForm}
                layout="vertical"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                <Form.Item label="First Name" name="fname">
                  <Row>
                    <Col span={22}>
                      <Input
                        defaultValue={mentorFirstName}
                        value={mentorFirstName}
                        size="medium"
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label="Last Name" name="lname">
                  <Row>
                    <Col span={22}>
                      <Input
                        defaultValue={mentorLastName}
                        value={mentorLastName}
                        size="medium"
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label="Email" name="email">
                  <Row>
                    <Col span={22}>
                      <Input
                        defaultValue={mentorEmail}
                        value={mentorEmail}
                        size="medium"
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label="Status">
                  <Row gutter={10}>
                    <Col span={22}>
                      <Input
                        type="email"
                        defaultValue={mentorStatus}
                        value={mentorStatus}
                        size="medium"
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item label="Currency">
                  <Row gutter={10}>
                    <Col span={22}>
                      <Input
                        defaultValue={userDetailsData.currency}
                        value={userDetailsData.currency}
                        size="medium"
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item label="Mentor Incentive">
                  <Row gutter={10}>
                    <Col span={22}>
                      <Input
                        defaultValue={incentive}
                        value={incentive}
                        size="medium"
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item name="currentCompany" label="Current Company">
                  <Col span={22}>
                    <Input
                      placeholder="Company Name"
                      size="medium"
                      defaultValue={mentorStatusData.currentCompany}
                      value={mentorStatusData.currentCompany}
                      readonly="readonly"
                    />
                  </Col>
                </Form.Item>
                <Form.Item name="linkedin" label="LinkedIn profile">
                  <Row>
                    <Col span={22}>
                      <a
                        href={mentorLinkedIn ? mentorLinkedIn : ""}
                        target="blank"
                      >
                        <Input
                          type="button"
                          value={mentorLinkedIn}
                          style={{
                            color: "#1890ff",
                            textAlign: "left",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="yoe" label="Experience in field">
                  <Row>
                    <Col span={22}>
                      <Input
                        placeholder="Experience"
                        size="medium"
                        defaultValue={printExperience(mentorStatusData.yoe)}
                        value={printExperience(mentorStatusData.yoe)}
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="mentoryoe" label="Experience as a mentor">
                  <Row>
                    <Col span={22}>
                      <Input
                        placeholder="Experience as mentor"
                        size="medium"
                        defaultValue={printExperience(
                          mentorStatusData.mentoryoe
                        )}
                        value={printExperience(mentorStatusData.mentoryoe)}
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="tshirt" label="T-shirt Size">
                  <Row>
                    <Col span={22}>
                      <Input
                        placeholder="T-shirt Size"
                        size="medium"
                        defaultValue={mentorStatusData.tshirt}
                        value={mentorStatusData.tshirt}
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="fullAddress" label="Full address">
                  <Row>
                    <Col span={22}>
                      <Input
                        placeholder="Full address"
                        size="medium"
                        defaultValue={mentorStatusData.fullAddress}
                        value={mentorStatusData.fullAddress}
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  name="mentorshipActivities"
                  label="mentorship activities"
                >
                  <Row>
                    <Col span={22}>
                      <Input.TextArea
                        autoSize={{
                          minRows: 5,
                          maxRows: 50,
                        }}
                        placeholder="mentorship activities"
                        size="medium"
                        defaultValue={mentorStatusData.mentorshipActivities}
                        value={mentorStatusData.mentorshipActivities}
                        readonly="readonly"
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  name="interestedInContributing"
                  label="What are you interested in contributing to?"
                  required
                  labelCol={{ span: "12" }}
                >
                  <Row>
                    <Col span={24}>
                      <Checkbox.Group
                        disabled
                        defaultValue={mentorStatusData.interestedInContributing}
                        value={mentorStatusData.interestedInContributing}
                        options={contributionOptions}
                      />
                      <Input
                        placeholder="others"
                        value={
                          mentorStatusData.interestedInContributing &&
                          mentorStatusData.interestedInContributing[
                            mentorStatusData.interestedInContributing.length - 1
                          ]
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  name="providingValue"
                  label="Roles you are comfortable providing value for "
                  required
                  labelCol={{ span: "12" }}
                >
                  <Row>
                    <Col span={24}>
                      <Checkbox.Group
                        defaultValue={mentorStatusData.providingValue}
                        value={mentorStatusData.providingValue}
                        options={rolesOptions}
                        disabled
                      />
                      <Input
                        placeholder="others"
                        value={
                          mentorStatusData.providingValue &&
                          mentorStatusData.providingValue[
                            mentorStatusData.providingValue.length - 1
                          ]
                        }
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item label="Bio" name="bio">
                  <Col span={22}>
                    <Input.TextArea
                      placeholder="You can include more details about your experience, fiels of expertise, etc. This will help us with assigning more accurate resumes to you."
                      autoSize={{
                        minRows: 5,
                        maxRows: 50,
                      }}
                      readonly="readonly"
                      defaultValue={mentorStatusData.bio}
                      value={mentorStatusData.bio}
                    />
                  </Col>
                  {mentorStatus !== "approved" ? (
                    <Form.Item>
                      <Button
                        disabled={mentorStatusData.linkedin === ""}
                        onClick={ApproveMentor.bind(
                          this,
                          userId,
                          mentorFullName,
                          "approve"
                        )}
                        style={{
                          background: "green",
                          color: "white",
                          marginTop: "5%",
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        disabled={mentorStatusData.linkedin === ""}
                        onClick={ApproveMentor.bind(
                          this,
                          userId,
                          mentorFullName,
                          "approve"
                        )}
                        style={{
                          background: "red",
                          color: "white",
                          marginTop: "5%",
                        }}
                      >
                        Decline
                      </Button>
                    </Form.Item>
                  ) : (
                    <Form.Item></Form.Item>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
