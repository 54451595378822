// admin useGetMentorStatus.js

import axios from "axios";
import { useQuery } from "react-query";
import { config } from "../utils/config";

const fetchStatus = () =>
  axios.get(`${config.baseUrl}/mentorStatusAdmin`).then((res) => res.data);

export default function useGetMentorStatus() {
  return useQuery("mentorStatus", fetchStatus, {
    retry: false,
    refetchOnWindowFocus: false,
  });
}

// Get Mentor Status from user-role based on userId supplied by admin
export function useGetMentorStatusData(userId) {
  return useQuery(
    "mentorStatusDetails",
    () =>
      axios(`${config.baseUrl}/mentorStatusAdmin`, {
        params: {
          userId,
        },
      }),
    {
      retry: false,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
}
