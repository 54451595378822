// prints experience in form of String. for example if 5 is the number of years of experience
// '5 years' will be printed  and when short is true '5y' will be printed
// @param {number} exp
// @param {Boolean} short
export const printExperience = (exp, short = false) => {
  if (Number.parseInt(exp) < 10) {
    return short ? `${exp}y` : `${exp} years`;
  } else if (Number.parseInt(exp) >= 10) {
    return short ? `${exp}-${exp + 5}y` : `${exp}-${exp + 5} years`;
  }
  return "";
};
