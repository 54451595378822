/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";

import isLoggedReducer from "./isLogged";
import isInitialPaymentReducer from "./initialPayment";
import collapseLeftbarReducer from "./collapseLeftbar";
import reviewContentReducer from "./reviewContentReducer";
import isLoadingReducer from "./loading";
/**
 * Merges the main reducer and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    isLogged: isLoggedReducer,
    isInitialPayment: isInitialPaymentReducer, // Check if this is used
    collapseLeftbar: collapseLeftbarReducer,
    reviewContent: reviewContentReducer,
    isLoading: isLoadingReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
