import { Layout } from "antd";
import "antd/dist/antd.css";
import React from "react";
import Leftnav from "../../components/Leftnav";
import PageContent from "../../components/PageContent";

export default class Page extends React.Component {
  render() {
    return (
      <Layout hasSider style={{ height: "calc(100vh - 4rem)" }}>
        <Leftnav />
        <PageContent />
      </Layout>
    );
  }
}
