/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-multi-str */
/* eslint-disable react-hooks/exhaustive-deps */
/*
 * ReviewResume Initial OrderDetails Page
 *
 */

import React, { useState, useEffect } from "react";
import "./index.css";
import { firestore, storage } from "../../../utils/firebase";
import { Alert, Button, Modal } from "antd";
import { createBrowserHistory } from "history";
import { config } from "../../../utils/config";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { Spin, Radio } from "antd";
import { Form, Row, Col } from "antd";
import tinymce from "tinymce/tinymce";

export default function Feedback(props) {
  const [feedback, setFeedback] = useState([]);
  const [resume, setResume] = useState([]);
  const [menteeid, setMenteeId] = useState([]);
  const [round, setRound] = useState([]);
  const [reviewid, setReviewid] = useState([]);
  const [currentRoundId, setcurrentRoundId] = useState([]);
  const [displayFeddback, setDisplayFeedback] = useState([]);
  const [mentorStatusForm] = Form.useForm();
  const [feedbackLoaded, setFeedbackLoaded] = useState(false);

  const [warning, setWarning] = useState(false);
  const onChangeEditor = (content, e) => {
    let changeData = [...feedback];
    changeData[parseInt(e.id)]["content"] = content;
    setFeedback(changeData);
  };
  const onChangeRadio = (e) => {
    let changeData = [...feedback];
    changeData[parseInt(e.target.name)]["content"] = e.target.value;
    setFeedback(changeData);
  };
  useEffect(async () => {
    let query = await firestore
      .collection("reviews")
      .doc(props.history.location.state.props)
      .collection("rounds")
      .doc(props.history.location.state.currentRoundId)
      .get();
    setFeedback(JSON.parse(query.data().feedback));
    setMenteeId(query.data().mentee);
    setRound(query.data().round);
    setReviewid(props.history.location.state.props);
    setcurrentRoundId(props.history.location.state.currentRoundId);
    await storage
      .ref(query.data().resume)
      .getDownloadURL()
      .then((url) => {
        setResume(url);
      });
  }, []);

  useEffect(() => {
    if (feedback && feedback.length > 0 && !feedbackLoaded) {
      let renderData = [];
      for (var index in feedback) {
        if (feedback[index]["type"] === "TEXT") {
          renderData.push(
            <Form.Item
              label={feedback[index]["heading"]}
              required={!feedback[index].isOptional}
            >
              <Row>
                <Col span={24}>
                  <Editor
                    init={{
                      setup: function (ed) {
                        ed.on("keyup", function (e) {
                          var body = ed.getBody();
                          const dataId = body.getAttribute("data-id");

                          var count = tinymce.trim(
                            body.innerText || body.textContent
                          ).length;
                          if ((count < 50 || count > 5000) && dataId !== "21") {
                            setWarning(true);
                          } else if (count > 50 || count < 5000) {
                            setWarning(false);
                          }
                        });
                      },
                      skin: false,
                      content_css: false,
                      menubar: false,
                      branding: false,
                      statusbar: false,
                      plugins: ["link image", "table paste"],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help",
                    }}
                    initialValue={feedback[index]["content"]}
                    id={index}
                    onEditorChange={onChangeEditor}
                  />
                </Col>
              </Row>
            </Form.Item>
          );
        } else if (feedback[index]["type"] === "RADIO") {
          renderData.push(
            <Form.Item label={feedback[index]["heading"]} required>
              <Row>
                <Col span={24}>
                  <Radio.Group
                    name={index}
                    onChange={onChangeRadio}
                    defaultValue={feedback[index]["content"]}
                  >
                    <Radio value={"Yes"}>Yes</Radio>
                    <Radio value={"No"}>No</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Form.Item>
          );
        }
      }
      setDisplayFeedback(renderData);
      setFeedbackLoaded(true);
    }
  }, [feedback, warning]);
  async function completeRound() {
    Modal.confirm({
      content: "Are you sure you want to send it to the candidate?",
      async onOk() {
        const payloadAssign = {
          menteeId: menteeid,
          round: round,
          reviewId: reviewid,
          roundId: currentRoundId,
          useCase: "COMPLETEROUND",
        };

        let query = await firestore
          .collection("reviews")
          .doc(props.history.location.state.props)
          .collection("rounds")
          .doc(currentRoundId);
        query
          .update({ feedback: JSON.stringify(feedback) })
          .then(async (res) => {
            await axios
              .post(`${config.baseUrl}/admin`, payloadAssign, {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              })
              .then(async (res) => {
                history.push("/reviews");
              });
          });
      },
      onCancel() {},
    });
  }

  return (
    <>
      {feedback && feedback.length > 0 && displayFeddback.length > 0 ? (
        <div className="profile__container">
          <Row>
            <h2
              style={{
                fontWeight: 700,
                paddingTop: 10,
                paddingBottom: 10,
                textAlign: "left",
              }}
            >
              Feedback
            </h2>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form
                form={mentorStatusForm}
                layout="vertical"
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 16 }}
              >
                {displayFeddback}
              </Form>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <iframe height="700" width="500" src={resume} />
            </Col>
          </Row>
          <Button
            disabled={warning}
            type="primary"
            onClick={completeRound}
            style={{ float: "right", margin: "5%" }}
          >
            SUBMIT
          </Button>
          {warning ? (
            <Alert
              message="Required fields must have minimum 50 and maximum 5000 characters"
              type="error"
            />
          ) : null}
        </div>
      ) : (
        <div className="spinner">
          <Spin />
        </div>
      )}
    </>
  );
}

export const history = createBrowserHistory({ forceRefresh: true });
