/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */

import { CheckOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";
import { Table } from "ant-table-extensions";
import { Input, message, Modal, Select, Spin, Tag } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { mentorStatusAdminAPIPath } from "../../../constants/apiPaths";
import { config } from "../../../utils/config";

import "./mentorTable.css";

const ApproveMentor = (props) => {
  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState(props.data);
  const dataSource = [];
  const [profileIcons, setProfileIcons] = useState({});
  const statusOptions = ["pending", "approved", "declined"];
  const [selectedStatusItems, setSelectedStatusItems] = useState([]);
  const filteredStatusOptions = statusOptions.filter(
    (o) => !selectedStatusItems.includes(o)
  );
  const [flagEmptyData, setFlagEmptyData] = useState(false);
  const limit = 25;
  const [page, setPage] = useState(1);

  const [searchField, setSearchField] = useState("");

  console.log("dataSource", dataSource);

  console.log("datas", datas);

  // SK new code added otherwise, setDatas was not getting set
  useEffect(() => {
    setDatas(props.data);
  }, [props.data]);

  useEffect(() => {
    let tempProfileIconMap = {};
    for (let index = 0; index < props.data.length; index += 1)
      tempProfileIconMap[index] = false;
    setProfileIcons(tempProfileIconMap);
  }, []);

  useEffect(() => {
    if (profileIcons) {
      const findIndex = Object.keys(profileIcons).find(
        (key) => profileIcons[key] === false
      );
      if (findIndex === -1 || !findIndex) setLoading(false);
    }
  }, [profileIcons]);

  const columnsData = [
    {
      title: "Mentor Name",
      dataIndex: "mentorDetail",
      key: "mentorDetail",
      width: 250,
      fixed: "left",
      render: (mentorDetail) => (
        <>
          <Link
            to={{
              pathname: `/mentors/${mentorDetail[0]}`,
              state: { props: mentorDetail },
            }}
          >
            {mentorDetail[1]}
          </Link>
        </>
      ),
      sorter: {
        compare: (a, b) =>
          a.mentorDetail[1]
            .toLowerCase()
            .localeCompare(b.mentorDetail[1].toLowerCase()),
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status),
      },
    },
    {
      title: "Approve",
      dataIndex: "approve",
      key: "approve",
      width: 200,
      render: (approve) => (
        <>
          {/*
        // approve[0,1,2,3] =>  approve[index, disableCheck, dta.mentorId, dta.mentorName],
        // CheckOutlined means tickmark symbol for approve and CloseOutlined means crossmark (X) for decline 
*/}
          {approve[1] ? (
            <div>
              {approve[4] ? (
                <>
                  <CheckOutlined
                    disabled
                    onClick={
                      approve[4]
                        ? approveMentor.bind(
                            this,
                            approve[0],
                            approve[2],
                            approve[3],
                            "approve"
                          )
                        : null
                    }
                    style={{
                      fontSize: "16px",
                      color: "green",
                      marginRight: "5%",
                    }}
                  />
                  <CloseOutlined
                    disabled
                    onClick={approveMentor.bind(
                      this,
                      approve[0],
                      approve[2],
                      approve[3],
                      "decline"
                    )}
                    style={{
                      fontSize: "16px",
                      color: "red",
                    }}
                  />
                </>
              ) : (
                "Mentor details not provided"
              )}
            </div>
          ) : (
            <CloseOutlined
              onClick={approveMentor.bind(
                this,
                approve[0],
                approve[2],
                approve[3],
                "decline"
              )}
              style={{ fontSize: "16px", color: "red" }}
            />
          )}
        </>
      ),
    },
    {
      title: "Reviews",
      dataIndex: "review",
      key: "review",
      width: 200,
      render: (review) => (
        <Link to={{ pathname: `/reviews/filters/${review}` }}>
          Click to open reviews
        </Link>
      ),
    },
  ];

  const approveMentor = async (index, id, name, status, e) => {
    // approve is : [index, disableCheck, dta.mentorId, dta.mentorName],
    //and  binding ApproveMentor with ==> aprove[0],approve[2],approve[3],'decline'
    // i.e. binding Approvementor with ==> index, uuid od mentor , name of mentor, status(approve or decline)

    let statusUpdate = status + "d";
    const displayData = "Click OK to " + status + " " + name + "'s profile!";
    Modal.confirm({
      content: displayData,
      async onOk() {
        setLoading(true);
        try {
          const payload = {
            userId: id,
            mentorstatus: statusUpdate,
          };
          await axios
            .put(mentorStatusAdminAPIPath, payload, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              let currentDatas = [...datas];
              currentDatas[index]["status"] = statusUpdate;
              setDatas(currentDatas);
            });

          setLoading(false);
        } catch (e) {
          message.error({
            content:
              e.response.data.message ||
              "Some internal server occurred While updating Mentor status",
            style: {
              marginTop: "85vh",
            },
          });
        }
      },
      onCancel() {
        console.log("Cancel from approve / decline approveMentor modal form");
      },
    });
  };

  useEffect(() => {
    setSearchDataSource(dataSource);
  }, [datas]);

  if (datas) {
    // map ( currentValue, index )
    datas.map((dta, index) => {
      const disableCheck = dta.status !== "approved";
      const diableApprove = dta.linkedin !== "";

      let details = [];

      details.push(dta.mentorId); // id Mentor dta[2]
      details.push(dta.mentorName); // Mentor Name dta[0]
      details.push(dta.status); // Mentor status dta[1]
      details.push(dta.mentorEmail);
      details.push(dta.linkedin);
      details.push(dta.role);
      details.push(dta.fname);
      details.push(dta.lname);

      const mentorData = {
        mentorDetail: details,
        status: dta.status,
        approve: [
          index,
          disableCheck,
          dta.mentorId,
          dta.mentorName,
          diableApprove,
        ],
        mentorName: dta.mentorName,
        review: dta.mentorId,
        linkedin: dta.linkedin,
        role: dta.role,
        email: dta.email,
      };

      dataSource.push(mentorData);

      return null;
    });
  }

  const [searchDataSource, setSearchDataSource] = useState(dataSource);
  console.log("searchDataSource", searchDataSource);
  const handleStatusChange = (statusItems) => {
    setSelectedStatusItems(statusItems);
  };
  const log = (id, e) => {
    e.preventDefault();
    const filterItem = id["item"];
    let itemsOfStatus = selectedStatusItems.filter((a) => a !== filterItem);
    setSelectedStatusItems(itemsOfStatus);
    if (itemsOfStatus.length > 0) {
      const filteredEventsProduct = dataSource.filter((dta) =>
        itemsOfStatus.includes(dta.status)
      );
      setSearchDataSource(filteredEventsProduct);
    } else {
      setSearchDataSource(dataSource);
    }
  };

  const onSearchFieldChange = (e) => {
    setSearchField(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    refetchSearchData();
  }, [searchField, selectedStatusItems]);

  const refetchSearchData = async () => {
    const payloadReview = {
      timestamp: 0,
      useCase: "USERROLE_MENTORS",
      pageType: "NEXT",
      search: searchField,
    };
    if (selectedStatusItems.length >= 1) {
      Object.assign(payloadReview, { mentorStatus: selectedStatusItems });
    }
    await axios
      .post(`${config.baseUrl}/admin`, payloadReview, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        if (res.data.length === 0) {
          setFlagEmptyData(true);
          setSearchDataSource([]);
        } else {
          props.setMentorData(res.data);
          console.log("datas", datas);
        }
      });
  };
  return (
    <>
      {loading ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
        <div className="container">
          <div className="reviewTable__header">
            <span className="reviewTable__header-title">
              <span className="reviewTable__header-left">
                <span className="reviewTable__header-text">
                  <h4
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      textAlign: "left",
                    }}
                  >
                    Filter
                  </h4>
                </span>
                Status
                <span
                  className="reviewTable__header-filter"
                  style={{ width: "50%" }}
                >
                  <Select
                    suffixIcon={
                      <DownOutlined
                        style={{
                          color: "#1890ff",
                        }}
                      />
                    }
                    showArrow
                    mode="multiple"
                    label="Pro"
                    value={selectedStatusItems}
                    onChange={handleStatusChange}
                    style={{ width: "100%" }}
                    className="dont-show"
                  >
                    {filteredStatusOptions.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </span>
              </span>
            </span>

            <span className="reviewTable__header-search">
              <Input onChange={onSearchFieldChange} />
            </span>
          </div>
          {selectedStatusItems.map((item) => (
            <Tag id={item} closable onClose={log.bind(this, { item })}>
              {item}
            </Tag>
          ))}
          <Table
            dataSource={searchDataSource} // filtered dataSource
            setDataSource={setSearchDataSource} // Newly created setSearchDataSource from useState hook
            columns={columnsData}
            scroll={{ x: "max-content" }}
            tableLayout="auto"
            pagination={{
              position: ["bottomRight"],
              current: page,
              pageSize: limit,
              total: props.totalData,
              defaultCurrent: 1,
              hideOnSinglePage: true,
              size: "small",
              onChange: async (currentPage) => {
                console.log("currentPage", currentPage);
                console.log("page", page);
                if (!flagEmptyData) {
                  setLoading(true);
                  let type = "NEXT";
                  let currentTmp = datas[datas.length - 1].created;
                  if (currentPage < page) {
                    type = "PREVIOUS";
                    currentTmp = datas[0].created;
                  }
                  const payloadReview = {
                    timestamp: currentTmp,
                    useCase: "USERROLE_MENTORS",
                    pageType: type,
                  };
                  await axios
                    .post(`${config.baseUrl}/admin`, payloadReview, {
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                    .then(async (res) => {
                      if (res.data.length === 0) {
                        setFlagEmptyData(true);
                        setSearchDataSource([]);
                      } else {
                        props.setMentorData(res.data);
                        console.log("datas", datas);
                        if (res.data.length === limit)
                          props.setTotalData(currentPage * limit + limit);
                      }
                    });
                  setLoading(false);
                } else {
                  setFlagEmptyData(false);
                  setSearchDataSource(dataSource);
                }
                setPage(currentPage);
              },
            }}
          />
        </div>
      )}
    </>
  );
};
export default ApproveMentor;
