/* eslint-disable func-names */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { getAuth } from "firebase/auth";
const {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} = require("firebase/app-check");

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = firebase.initializeApp(firebaseConfig);
firebase.auth();

// eslint-disable-next-line no-restricted-globals
self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_DEBUG_TOKEN;

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_RECAPTCHA_TOKEN
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

// Get a reference to the storage service, which is used to create references in your storage bucket

export const auth = getAuth(app);
export const firestore = firebase.firestore();
export const storage = firebase.storage();

if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.storage().useEmulator("localhost", 9199);
  firebase.firestore().settings({
    host: "localhost:8080",
    ssl: false,
    experimentalForceLongPolling: true,
  });
  firebase.firestore.setLogLevel("debug");
}

export default firebase;
