/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
import { message } from "antd";
import firebase, { auth } from "./index";

export const authMethods = {
  // firebase helper methods go here...
  signup: (email, password, setErrors, setToken, setNewuser) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      // make res asynchonous so that we can make grab the token before saving it.
      .then(async (res) => {
        const token = await Object.entries(res.user)[7][1];
        // set token to localStorage
        await localStorage.setItem("token", token);

        setToken(window.localStorage.token);
        setNewuser(res.additionalUserInfo.isNewUser); // set userdetail over here
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        console.log(err);
        message.error({
          content: err.message,
          className: "message__error",
          style: {
            marginTop: "85vh",
          },
        });
      }),
  signin: (email, password, setErrors, setToken) =>
    // change from create users to...
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      // everything is almost exactly the same as the function above
      .then(async (res) => {
        const token = await Object.entries(res.user)[7][1];
        // set token to localStorage
        await localStorage.setItem("token", token);
        await localStorage.setItem("userName", res.user.displayName);
        await localStorage.setItem("userImage", res.user.photoURL);
        await localStorage.setItem("userEmail", res.user.email);
        setToken(window.localStorage.token);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);

        message.error({
          content: err.message,
          className: "message__error",
          style: {
            marginTop: "85vh",
          },
        });
      }),
  // no need for email and password
  signout: (setErrors, setToken) =>
    // signOut is a no argument function
    firebase
      .auth()
      .signOut()
      .then(() => {
        // remove the token
        localStorage.clear();
        // set the token back to original state
        setToken(null);
      })
      .catch((err) => {
        // there shouldn't ever be an error from firebase but just in case
        setErrors((prev) => [...prev, err.message]);
        // whether firebase does the trick or not i want my user to do their thing.
        localStorage.clear();
        setToken(null);
        console.error(err.message);
        message.error({
          content: err.message,
          className: "message__error",
          style: {
            marginTop: "85vh",
          },
        });
      }),

  signingoogle: (setErrors, setToken, setNewuser) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (res) => {
        const token = await auth.currentUser.getIdToken();
        // set token to localStorage
        await localStorage.setItem("token", token);
        await localStorage.setItem("userName", res.user.displayName);
        await localStorage.setItem("userImage", res.user.photoURL);
        await localStorage.setItem("userEmail", res.user.email);
        await localStorage.setItem("newUser", res.additionalUserInfo.isNewUser);
        await setNewuser(res.additionalUserInfo.isNewUser);
        await setToken(window.localStorage.token);

        let fullName;
        let firstName;
        let lastName;
        if (res.user.displayName !== null) {
          fullName = res.user.displayName.split(" ");
          if (fullName !== null && fullName.length === 2) {
            firstName = fullName[0];
            lastName = fullName[1];
          }
        }

        const userDetails = {
          fname: firstName || "Welcome",
          lname: lastName || "User",
          profilePhoto:
            res.user.photoURL ||
            "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
          linkedin: "",
          mentorRating: 1,
        };

        return userDetails;
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        if (err.code !== "auth/popup-closed-by-user") {
          message.error({
            content: `${err.message}`,
            className: "message__error",
            style: {
              marginTop: "85vh",
            },
          });
        }
      });
  },

  signinfacebook: (setErrors, setToken, setNewuser) => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (res) => {
        const token = await Object.entries(res.user)[7][1];
        // set token to localStorage
        await localStorage.setItem("token", token);
        await localStorage.setItem("userName", res.user.displayName);
        await localStorage.setItem("userImage", res.user.photoURL);
        await localStorage.setItem("userEmail", res.user.email);
        setNewuser(res.additionalUserInfo.isNewUser);
        setToken(window.localStorage.token);
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        if (err.code !== "auth/popup-closed-by-user") {
          message.error({
            content: `${err.code} ${err.message}`,
            className: "message__error",
            style: {
              marginTop: "85vh",
            },
          });
        }
      });
  },

  forgotPassword: (email, setErrors) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(async () => {
        // Email sent.
        await message.success({
          content: `Password reset link sent to ${email} successfully!`,
          className: "message__error",
          style: {
            marginTop: "85vh",
          },
        });
      })
      .catch((err) => {
        setErrors((prev) => [...prev, err.message]);
        message.error({
          content: err.message,
          className: "message__error",
          style: {
            marginTop: "85vh",
          },
        });
      });
  },
};
