const user = localStorage.getItem("user");
const initialState = user ? { loggedIn: true, user } : {};

const isLoggedReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        loggedIn: true,
        user: action.user,
      };
    case "LOGOUT":
      return {
        loggedIn: false,
        user: null,
      };
    default:
      return state;
  }
};

export default isLoggedReducer;
