import AssignMentorTable from "./assignMentorTable";
import "./assignMentor.css";

export default function display3() {
  return (
    <div className="contentWrapper">
      <AssignMentorTable />
    </div>
  );
}
