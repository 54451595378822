/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/*
 * Review Table
 *
 */

import { Table } from "ant-table-extensions";
import { Spin } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { config } from "../../../utils/config";
import columns from "./columns";
import "./orderTable.css";
import TableFilters from "./tableFilters.js";

export default function OrderTable({ props }) {
  const limit = 25;
  const [page, setPage] = useState(1);
  const [flagEmptyData, setFlagEmptyData] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [datas, setdatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState();
  const [selectedProductItems, setSelectedProductItems] = useState([]);
  const [selectedPriceItems, setSelectedPriceItems] = useState([]);

  const dataSource = [];

  //fetch data
  useEffect(async () => {
    let orderData = [];
    const payload = {
      timestamp: 0,
      useCase: "ORDER_DETAILS",
      pageType: "NEXT",
      search: searchData,
    };
    if (selectedPriceItems.length >= 1) {
      Object.assign(payload, { price: selectedPriceItems });
    }
    if (selectedProductItems.length >= 1) {
      Object.assign(payload, { product: selectedProductItems });
    }
    await axios
      .post(`${config.baseUrl}/admin`, payload, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        orderData = res.data;
        if (res.data.length === limit) setTotalData(2 * limit);
        else setTotalData(limit);
        let tempProfileIconMap = {};
        for (let index = 0; index < res.data.length; index += 1)
          tempProfileIconMap[index] = false;
      });
    setdatas(orderData);
    setLoading(false);
  }, [searchData, selectedPriceItems, selectedProductItems]);

  useEffect(() => {
    setSearchDataSource(dataSource);
  }, [datas]);

  if (datas)
    datas.map((dta, indexVal) => {
      let username = "-";
      let details = ["-"];

      if (dta.user) username = dta.fname + " " + dta.lname;

      details = [];

      // below data comes from order details collection , if data not present make a call to user-role for that doc id
      details.push(dta.fname);
      details.push(dta.lname);
      details.push(username);
      // details.push(dta.email);
      details.push(dta.user);

      const orderItems = {
        key: dta.id,
        product: dta.product,
        created: new Date(dta.created).toLocaleDateString("en-US"),
        price: dta.price,
        status: dta.status,
        firstName: !dta.fname ? "-" : dta.fname + dta.lname,
        updated: new Date(dta.updated).toLocaleDateString("en-US"), //need to display mentor and mentee details here
        updatedEpoch: dta.updated,
        fname: details,
      };

      dataSource.push(orderItems);

      return null;
    });

  const [searchDataSource, setSearchDataSource] = useState(dataSource); // required

  const handleChangeDataSource = (updatedDataSource) => {
    setSearchDataSource(updatedDataSource);
  };
  return (
    <>
      {loading ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
        <div className="container">
          <TableFilters
            dataSource={dataSource}
            handleChangeDataSource={handleChangeDataSource.bind(this)}
            setSearchData={setSearchData}
            selectedPriceItems={selectedPriceItems}
            selectedProductItems={selectedProductItems}
            setSelectedPriceItems={setSelectedPriceItems}
            setSelectedProductItems={setSelectedProductItems}
          />
          <Table
            dataSource={searchDataSource} // filtered dataSource
            setDataSource={setSearchDataSource} // Newly created setSearchDataSource from useState hook
            columns={columns}
            scroll={{ x: "max-content" }}
            tableLayout="auto"
            pagination={{
              current: page,
              pageSize: limit,
              total: totalData,
              defaultCurrent: 1,
              onChange: async (currentPage) => {
                if (!flagEmptyData) {
                  setLoading(true);
                  let type = "NEXT";
                  let currentTmp =
                    dataSource[dataSource.length - 1].updatedEpoch;
                  if (currentPage < page) {
                    type = "PREVIOUS";
                    currentTmp = dataSource[0].updatedEpoch;
                  }
                  const payloadReview = {
                    timestamp: currentTmp,
                    useCase: "ORDER_DETAILS",
                    pageType: type,
                  };
                  await axios
                    .post(`${config.baseUrl}/admin`, payloadReview, {
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                    .then(async (res) => {
                      if (res.data.length === 0) {
                        setFlagEmptyData(true);
                        setSearchDataSource([]);
                      } else {
                        setdatas(res.data);
                        if (res.data.length === limit)
                          setTotalData(currentPage * limit + limit);
                      }
                    });
                  setLoading(false);
                } else {
                  setFlagEmptyData(false);
                  setSearchDataSource(dataSource);
                }
                setPage(currentPage);
              },
            }}
          />
        </div>
      )}
    </>
  );
}
