/* eslint-disable react-hooks/exhaustive-deps */

import { Table } from "ant-table-extensions";
import { Form, Input, message, Modal, Select, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useGetReassignmentReasons from "../../../hooks/useReassignmentReason";
import { config } from "../../../utils/config";
import { printExperience } from "../../../utils/experience";
import { firestore } from "../../../utils/firebase";
import "./assignMentorTable.css";
import columns from "./columns";
import TableFilters from "./tableFilters.js";
const { Option } = Select;
export default function AssignMentorTable(props) {
  const [datas, setdatas] = useState(props.data);
  const limit = 25;
  const [page, setPage] = useState(props.currentPage);
  const [mentorData] = useState(props.mentorData);
  const [flagEmptyData, setFlagEmptyData] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [dropdownSelected, setDropdownSelected] = useState(false);
  const [reassignmentReason, setReassignmentReason] = useState(null);
  const [notes, setNotes] = useState(null);
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState(null);
  const [search, setSearch] = useState();
  const [review, setReview] = useState([]);
  const [status, setStatus] = useState([]);
  const [experience, setExperience] = useState([]);
  const [role, setRole] = useState([]);
  const [round, setRound] = useState([]);

  const dataSource = [];
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const { data } = useGetReassignmentReasons();

  const jobProfilesItems = data;

  useEffect(() => {
    setSearchDataSource(dataSource);
  }, [datas]);

  useEffect(() => {
    const currentData = (props.currentPage - 1) * limit;
    if (
      props.data &&
      props.data.reviewData &&
      props.data.reviewData.length === limit
    )
      setTotalData(2 * limit + currentData);
    else setTotalData(limit + currentData);
  }, [props]);

  function onFocus(e) {
    setLoading(true);
    setDropdownSelected(true);
    setLoading(false);
  }
  function onMouseLeave(e) {
    setLoading(true);
    setDropdownSelected(false);
    setLoading(false);
  }
  function onMouseEnter(e) {
    setLoading(true);
    setDropdownSelected(true);
    setLoading(false);
  }

  const onNotesChange = (e) => {
    setNotes(e.target.value);
  };
  const onReasonChange = (value) => {
    setReassignmentReason(value);
  };

  const onCancel = () => {
    setdatas(datas);
    setVisible(false);
  };

  const onOk = async () => {
    setLoading(true);
    let [menteeid, mentorid, round, reviewid, id, currentRoundId] =
      key.key.split(",");
    let mentorStatus = "pending";
    await firestore
      .collection("user-role")
      .doc(mentorid)
      .collection("mentorStatus")
      .doc(mentorid)
      .get()
      .then((snapshot) => {
        mentorStatus = snapshot.data().mentorstatus;
      });
    let incentive = "";

    if (mentorStatus !== "approved") {
      await setdatas(datas);
      message.error({
        content:
          `Please approve the mentor ` + key.children + ` before assigning`,
        style: {
          marginTop: "85vh",
        },
      });
      setLoading(false);
      return;
    }

    // SK get incentive from userdetail for this mentor
    await firestore
      .collection("user-role")
      .doc(mentorid)
      .collection("userdetail")
      .doc(mentorid)
      .get()
      .then((snapshot) => {
        incentive = snapshot.data().incentive;
      });

    if (incentive === "" || incentive === 0) {
      await setdatas(datas);
      message.error({
        content: `Please assign incentive to the mentor ` + key.children,
        style: {
          marginTop: "85vh",
        },
      });
      setLoading(false);
      return;
    }

    if (reassignmentReason === null && key.key.split(",")[8] !== "") {
      message.error({
        content: "Please select a reason for reassignment",
        style: {
          marginTop: "85vh",
        },
      });
      setLoading(false);
      return;
    }
    if (reassignmentReason === "others" && notes === null) {
      message.error({
        content: "Please provide a note for reassignment",
        style: {
          marginTop: "85vh",
        },
      });
      setLoading(false);
      return;
    }

    let reasonForReassignment;

    if (reassignmentReason === "others") {
      reasonForReassignment = `${reassignmentReason}- ${notes}`;
    } else {
      reasonForReassignment = reassignmentReason;
    }

    let payloadAssign;

    if (reassignmentReason !== null) {
      payloadAssign = {
        mentorId: mentorid,
        menteeId: menteeid,
        round: round,
        reviewId: reviewid,
        roundId: currentRoundId,
        useCase: "ASSIGNMENTOR",
        reassignmentReason: { [mentorid]: reasonForReassignment },
      };
    } else {
      payloadAssign = {
        mentorId: mentorid,
        menteeId: menteeid,
        round: round,
        reviewId: reviewid,
        roundId: currentRoundId,
        useCase: "ASSIGNMENTOR",
      };
    }
    await axios
      .post(`${config.baseUrl}/admin`, payloadAssign, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        let currentData = { ...datas };
        let [fname, lname] = key["children"].split(" ");
        currentData["reviewData"][id]["mentor"][round] = mentorid;
        currentData["reviewData"][id]["mentorlist"].push(mentorid);
        currentData["reviewData"][id]["status"][round - 1] = "MENTORASSIGNED";
        currentData["userIdMap"][mentorid] = [];
        currentData["userIdMap"][mentorid].push({
          fname: fname,
          lname: lname,
        });
        await setdatas(currentData);
        setReassignmentReason(null);
        setNotes(null);
        message.success({
          content: `Mentor Assignment is successful`,
          style: {
            marginTop: "85vh",
          },
        });
      })
      .catch((err) => {
        message.error({
          content: err.response.data.message || "Some internal server occurred",
          style: {
            marginTop: "85vh",
          },
        });
      });
    setReassignmentReason(null);
    setNotes(null);
    setLoading(false);
    setVisible(false);
  };

  async function onChange(value, key) {
    setKey(key);
    setVisible(true);
  }

  if (datas["reviewData"]) {
    datas["reviewData"].map((dta, id) => {
      const OrderIndex = dta.mentorlist.findIndex(
        (value) => value === props.orderId
      );

      if (
        (!props.orderId ||
          props.orderId === dta.order ||
          dta.mentor[props.orderId] ||
          OrderIndex !== -1) &&
        datas["userIdMap"][dta.mentee].length > 0
      ) {
        const name =
          datas["userIdMap"][dta.mentee][0]["fname"] +
          " " +
          datas["userIdMap"][dta.mentee][0]["lname"];

        let roundNo = dta.currentRound;
        let status = dta.status[roundNo - 1];
        let mentor = [];
        let mentorDetail = [];
        let mentorName = "";
        mentor.push(
          <Select
            showSearch
            style={{ width: 150 }}
            placeholder="Select a mentor"
            optionFilterProp="children"
            onChange={onChange}
            value={null}
            disabled={
              status === "SENTFORAPPROVAL" ||
              status === "DONE" ||
              status === "WAITINGFORDETAILS" ||
              status === "WAITINGFORRESUME"
                ? true
                : false
            }
            onFocus={onFocus}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {mentorData.map((object, i) => (
              <Option
                value={object["name"]}
                key={[
                  dta.mentee,
                  object["mentorID"],
                  roundNo,
                  dta.id,
                  id,
                  dta.currentRoundId,
                  name,
                  object["incentive"],
                  dta.mentor[dta.currentRound],
                ]}
              >
                {object["name"]}
              </Option>
            ))}
          </Select>
        );
        if (dta.mentor && dta.mentor[dta.currentRound]) {
          const idOfMentor = dta.mentor[dta.currentRound];
          mentorDetail = dta.mentor;
          const nameOfMentor =
            datas["userIdMap"][idOfMentor][0]["fname"] +
            " " +
            datas["userIdMap"][idOfMentor][0]["lname"];
          mentorName = nameOfMentor;
        } else {
          mentorName = "-";
        }
        let targetJobs = [];
        if (
          dta.questionnaire[
            "Links to target jobs you are interested in applying to"
          ] &&
          dta.questionnaire[
            "Links to target jobs you are interested in applying to"
          ].length > 0
        )
          for (
            var index = 0;
            index < dta.questionnaire &&
            dta.questionnaire[
              "Links to target jobs you are interested in applying to"
            ] &&
            dta.questionnaire[
              "Links to target jobs you are interested in applying to"
            ].length;
            index += 1
          ) {
            targetJobs.push({
              name:
                dta.questionnaire &&
                dta.questionnaire["Job Title"] &&
                dta.questionnaire["Job Title"][index],
              url: dta.questionnaire[
                "Links to target jobs you are interested in applying to"
              ][index],
            });
          }
        const reviewDetails = {
          job_profile: dta.reviewName,
          experience: printExperience(dta.experience),
          type_of_emp: dta.role,
          review_extra_details:
            dta.questionnaire &&
            dta.questionnaire["Additional Details We should know"],
          targetJobs: targetJobs,
        };

        // // SK this data is used to display mentee details is in mentorAssign and then  dataSource.push(mentorAssign);
        // console.log ( " mentee for display in column = " , name) ;
        // console.log ( "menteeData: ", [dta.mentee, datas['userIdMap'][dta.mentee][0]] ) ;

        const mentorAssign = {
          productID: dta.id,
          product: dta.product,
          mentorIncentive: dta.mentorIncentive,
          reviewName: dta.productTitle,
          mentee: name,
          menteeData: [dta.mentee, datas["userIdMap"][dta.mentee][0]],
          rounds: dta.rounds,
          role: dta.role,
          productTitle: dta.reviewName,
          experience: printExperience(dta.experience),
          roundNo: roundNo,
          status: [status, dta.id, dta.currentRoundId],
          statusDetails: status,
          targetJobs:
            dta.questionnaire &&
            dta.questionnaire[
              "Links to target jobs you are interested in applying to"
            ],
          targetTitle:
            dta.questionnaire &&
            dta.questionnaire["Enter the job job title you are interested in"],
          tags: dta.tags,
          dueDate:
            dta.dueDate === -1
              ? "-"
              : new Date(dta.dueDate).toLocaleDateString("en-US"),
          created: new Date(dta.created).toLocaleDateString("en-US"),
          updated: new Date(dta.updated).toLocaleDateString("en-US"),
          updatedEpoch: dta.updated,
          mentorName: mentorName,
          mentor: mentor,
          roundDetails: [
            dta.id,
            mentorDetail,
            dta.status,
            dta.currentRound,
            reviewDetails,
          ],
          reviewExtraDetails:
            dta.questionnaire["Additional Details We should know"],
        };

        dataSource.push(mentorAssign);
      }
      return null;
    });
  }
  const [searchDataSource, setSearchDataSource] = useState(dataSource);
  useEffect(() => {
    const currentData = (props.currentPage - 1) * limit;
    if (searchDataSource.length === limit)
      setTotalData(2 * limit + currentData);
    else setTotalData(limit + currentData);
  }, [searchDataSource]);

  const handleChangeDataSource = (updatedDataSource) => {
    setSearchDataSource(updatedDataSource);
  };

  const fetchSearchData = async () => {
    const payloadReview = {
      timestamp: 0,
      useCase: "REVIEW_DETAILS",
      pageType: "NEXT",
      search: search,
    };
    if (role.length >= 1) {
      Object.assign(payloadReview, { role: role });
    }
    if (experience.length >= 1) {
      Object.assign(payloadReview, { experience: experience });
    }
    if (status.length >= 1) {
      Object.assign(payloadReview, { status: status });
    }
    if (round.length >= 1) {
      Object.assign(payloadReview, { round: round });
    }
    if (review.length >= 1) {
      Object.assign(payloadReview, { reviews: review });
    }
    await axios
      .post(`${config.baseUrl}/admin`, payloadReview, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setdatas(res.data);
      });
  };

  useEffect(() => {
    fetchSearchData();
  }, [search, round, role, status, experience, review]);

  return (
    <>
      {loading ? (
        <div className="spinner">
          <Spin />
        </div>
      ) : (
        <div className="container">
          <TableFilters
            setSearch={setSearch}
            setRound={setRound}
            setRole={setRole}
            setExperience={setExperience}
            setReview={setReview}
            setStatus={setStatus}
            dataSource={dataSource}
            handleChangeDataSource={handleChangeDataSource.bind(this)}
          />
          <Table
            dataSource={searchDataSource} // filtered dataSource
            setDataSource={setSearchDataSource} // Newly created setSearchDataSource from useState hook
            columns={columns}
            scroll={{ x: "max-content" }}
            tableLayout="auto"
            onRow={(record) => ({
              onClick: (e) => {
                if (
                  window.location.pathname.includes("/reviews") &&
                  !dropdownSelected
                )
                  history.push({
                    pathname: `/reviews/details/${record.roundDetails[0]}`,
                    state: {
                      props: {
                        round: record.roundDetails[0],
                        mentorDetail: record.roundDetails[1],
                        status: record.roundDetails[2],
                        reviewDetails: record.roundDetails[4],
                        current: record.roundDetails[3],
                      },
                    },
                  });
              },
            })}
            pagination={{
              current: page,
              pageSize: limit,
              total: totalData,
              defaultCurrent: 1,
              onChange: async (currentPage) => {
                if (!flagEmptyData) {
                  setLoading(true);
                  let type = "NEXT";
                  let currentTmp =
                    dataSource[dataSource.length - 1].updatedEpoch;
                  if (currentPage < page) {
                    type = "PREVIOUS";
                    currentTmp = dataSource[0].updatedEpoch;
                  }
                  const payloadReview = {
                    timestamp: currentTmp,
                    useCase: "REVIEW_DETAILS",
                    pageType: type,
                  };
                  await axios
                    .post(`${config.baseUrl}/admin`, payloadReview, {
                      headers: {
                        Authorization:
                          "Bearer " + localStorage.getItem("token"),
                      },
                    })
                    .then(async (res) => {
                      if (res.data.reviewData.length === 0) {
                        await setFlagEmptyData(true);
                        await setSearchDataSource([]);
                      } else {
                        await setdatas(res.data);
                        await props.handlePaginatedData(res.data);
                        if (res.data.reviewData.length === limit)
                          await setTotalData(currentPage * limit + limit);
                      }
                    });
                  setLoading(false);
                } else {
                  setFlagEmptyData(false);
                  setSearchDataSource(dataSource);
                }
                props.handleCurrentPage(currentPage);
                setPage(currentPage);
              },
            }}
          />
          <Modal
            title="Assign mentor"
            centered
            visible={visible}
            okText="Confirm"
            cancelText="Cancel"
            onCancel={onCancel}
            className="addResume__modal"
            onOk={onOk}
            width={600}
          >
            <div>
              <div>
                <p>
                  Click OK to assign {key && key.children} as the mentor to
                  &nbsp;
                  {key && key.key && key.key.split(",")[6]}.
                </p>
              </div>
              {key && key.key && key.key.split(",")[8] !== "" ? (
                <div>
                  <br />
                  Reason For Reaasignment : <br />
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select a reason"
                    optionFilterProp="children"
                    onChange={onReasonChange}
                    dropdownRender={(menu) => <div>{menu}</div>}
                  >
                    {jobProfilesItems.map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                  <Form.Item label="Notes" style={{ marginTop: "1rem" }}>
                    <Input placeholder="notes" onChange={onNotesChange} />
                  </Form.Item>
                </div>
              ) : null}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}
