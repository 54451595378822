import { Layout } from "antd";
import "antd/dist/antd.css";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Display1 from "../../components/display1";
import MenteeProfile from "../../components/display1/menteeProfile";
import Display2 from "../../components/display2";
import Display3 from "../../components/display3";
import AdminApproval from "../../components/display3/adminApproval";
// SK mentee profile added in the display 3 screen
import MenteeProfile3 from "../../components/display3/menteeProfile3";
import PushNotification from "../../containers/PushNotification";
import Resource from "../../containers/Resource";
import "../../pages/Home/home.css";
function PageContent() {
  return (
    <Layout className="site-layout">
      <Switch>
        <Redirect exact from="/" to="/orders" />
        <Route exact path="/orders" component={Display1} />
        <Route
          exact
          path="/mentee/:userid"
          render={(props) => <MenteeProfile {...props} />}
        />
        <Route
          exact
          path="/mentee3/:userid"
          render={(props) => <MenteeProfile3 {...props} />}
        />
        <Route path="/mentors" component={Display2} />
        <Route exact path="/reviews/filters/:orderId" component={Display3} />
        <Route path="/reviews" component={Display3} />
        <Route
          path="/feedback/:userid"
          render={(props) => <AdminApproval {...props} />}
        />
        <Route path="/resources" component={Resource} />
        <Route path="/pushnotification" component={PushNotification} />
      </Switch>
    </Layout>
  );
}

export default PageContent;
